// Global Navigation

// 상단 네비게이션 전체 감싸는 영역
.navbar {
  position: fixed;
  top: 0;
  z-index: var(--z-index-header);

  @include size(100%, var(--navbar-Mobile));

  background-color: var(--Static-White);

  @include media-breakpoint-up('lg') {
    height: var(--navbar-Desktop);
  }

  &.navbar-expand {
    height: var(--navbar-MobileExpand);

    @include media-breakpoint-up('lg') {
      height: var(--navbar-Desktop);
    }
  }

  // 폐쇄몰 보완
  &.closure {
    @include media-breakpoint-up('lg') {
      height: var(--navbar-DesktopClosure);
    }

    .navbar-collapse {
      .nav-link {
        + .nav-link {
          margin-left: 1rem;

          @include media-breakpoint-up('lg') {
            margin-left: 1.75rem;
          }
        }

        .badge-amount {
          right: unset;
          left: 12px;

          @include media-breakpoint-up('lg') {
            left: 20px;
          }
        }
      }

      .link-sm-group {
        display: none;
        font-size: var(--font-body-2);
        margin-left: 4rem;
        color: var(--Static-White);

        @include media-breakpoint-up('lg') {
          display: inline-block;
        }

        .nav-link {
          &:hover {
            text-decoration: underline;
          }

          + .nav-link {
            margin-left: 1rem;
          }
        }
      }
    }

    .nav-desktop-label {
      display: none;
      color: var(--Static-White);
      font-weight: 700;
      margin-left: 0.5rem;
      height: 2rem;

      &.active {
        border-bottom: 0.125rem solid var(--Static-White);
      }

      @include media-breakpoint-up('lg') {
        display: inline-block;
      }
    }
  }

  // 에러, 비밀번호 재설정 등 사용되는 로고 바
  &.blank {
    height: var(--navbar-Blank);
    background-color: var(--Theme-Primary);
    text-align: center;
    display: inline-flex;

    .navbar-brand {
      text-align: center;
      width: 100%;
      margin-top: 12px;

      img {
        @include size(auto, 1.25rem);
      }
    }
  }

  // 녹색 바
  .fluid-main {
    padding: 0 1rem;

    @include size(100%, var(--navbar-Mobile));
    background-color: var(--Theme-Primary);

    @include media-breakpoint-up('md') {
      padding: 0 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      height: 4rem;
    }

    @include media-breakpoint-up('xlg') {
      padding: 0;
    }
  }

  // 흰색 바
  .fluid-sub {
    @include size(100%, 3rem);
    background-color: var(--Static-White);
    border-bottom: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('lg') {
      height: 3.5rem;
    }

    .tabs {
      width: 100%;
      padding: 0 1rem;
      border-bottom: 0;
      background-color: transparent;

      @include media-breakpoint-up('md') {
        padding: 0 1.5rem;
      }

      @include media-breakpoint-up('lg') {
        width: auto;
      }

      @include media-breakpoint-up('xlg') {
        padding: 0;
      }
    }

    .tab-item {
      font-size: var(--font-body-2);
      height: 3rem;
      line-height: 3rem;

      @include media-breakpoint-up('lg') {
        height: 3.5rem;
        line-height: 3.5rem;

        + .tab-item {
          margin-left: 1.5rem;
        }
      }

      @include media-breakpoint-up('xlg') {
        margin: 0;
      }
    }

    .navbar-collapse {
      display: none;

      @include media-breakpoint-up('lg') {
        display: block;
        padding-right: 1.5rem;
      }

      @include media-breakpoint-up('xlg') {
        padding-right: 0;
      }

      .nav-link {
        font-size: var(--font-body-3);
        color: var(--Font-Middle);
        margin-top: 1.25rem;
      }
    }
  }

  // 공통 감싸는영역
  .contents-wrap {
    display: flex;

    @include media-breakpoint-up('xlg') {
      padding-left: 0;
      padding-right: 0;

      max-width: 1128px; // flexible + 36px gutter
      margin-left: auto;
      margin-right: auto;
    }
  }

  // 공통 링크 그룹
  .navbar-collapse {
    flex: 1;
    text-align: right;

    .nav-link {
      position: relative;
      display: inline-block;
      @include size(auto);
      margin-top: 0.94rem;

      .tooltip:hover .tooltiptext {
        visibility: visible;
      }

      + * {
        margin-left: 1rem;
      }

      @include media-breakpoint-up('lg') {
        margin-top: 1.06rem;
      }

      &.link-mobile {
        @include media-breakpoint-up('lg') {
          display: none;
        }
      }

      &.link-desktop {
        display: none;

        @include media-breakpoint-up('lg') {
          display: inline-block;
        }
      }

      img {
        @include size(1.5rem);

        @include media-breakpoint-up('lg') {
          @include size(2rem);
        }
      }

      .tooltiptext {
        visibility: hidden;
        @include size(4rem, 1.75rem);
        line-height: 1.75rem;
        text-align: center;
        padding: 0;
        border-radius: var(--radius-full);
        font-size: var(--font-body-3);

        border: 1px solid var(--Theme-Primary);
        background-color: var(--Background-SelectInfo);

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 50%;
        margin-left: -2rem;

        /* ShadowSmall */
        box-shadow: 0px 2px 4px 0px rgba(24, 24, 27, 0.16);
      }

      &:hover {
        .tooltiptext {
          visibility: visible;
          display: block;
        }
      }
    }
  }

  // 브랜드 로고
  .navbar-brand {
    @include size(auto);
    margin-top: 0.94rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up('lg') {
      margin-top: 1.06rem;
    }

    img {
      @include size(6.1875rem, 1.5rem);

      @include media-breakpoint-up('lg') {
        @include size(7.75rem, 1.875rem);
      }
    }
  }

  // 네비게이션 검색
  .forms-search {
    display: none;

    .search {
      border: none;
      border-radius: var(--radius-full);
      height: 2.5rem;
    }

    .search-submit {
      top: 0.35rem;
    }

    @include media-breakpoint-up('lg') {
      display: inline-block;
      width: 30rem;
      margin-right: 3rem;
    }
  }

  // 모바일용 메뉴 아이콘
  .navbar-toggler {
    cursor: pointer;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  // 데스크탑에서만 보이는 네비게이션
  .desktop-bar {
    display: none;

    @include media-breakpoint-up('lg') {
      display: block;
    }
  }

  // Title bar (모바일에서만 보이는 네비게이션)
  .title-bar {
    display: flex;
    @include size(100%, var(--navbar-Mobile));
    font-weight: 700;
    padding: 0 1rem;
    border-bottom: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('md') {
      padding: 0 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      display: none;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-top: 0.94rem;
    }

    .navbar-collapse {
      flex: none;
    }

    .btn-close {
      display: block;
      margin-top: 0.94rem;

      > img {
        @include size(1.5rem);
      }
    }
  }
}

// 데스크탑 탭 메뉴 (펼쳐졌을 때)
.navbar-tabs-menu {
  position: absolute;
  left: 0;
  top: 3rem;

  padding: 1rem 1rem 1.5rem 1rem;

  border-radius: var(--radius-s);
  border: 1px solid var(--Border-DividerHeavy);
  background: var(--Static-White);

  /* ShadowLarge */
  box-shadow: 0px 8px 16px 0px rgba(44, 53, 88, 0.1);

  @include size(100%, auto);

  @include media-breakpoint-up('lg') {
    top: 3.5rem;
    @include size(29rem, auto);
  }

  .menu-list {
    font-size: 0;

    display: grid;
    grid-template-columns: repeat(3, auto);
  }

  .menu-item {
    font-size: var(--font-body-1);
    @include size(100%, auto);
    padding: 0.5rem 1rem;

    a {
      display: block;
    }
  }

  &.brand {
    .menu-list {
      grid-template-columns: repeat(2, auto);
    }

    .menu-item {
      @include size(100%, auto);
      padding: 1rem;

      img {
        @include size(2rem);
        margin-right: 0.5rem;
      }
    }
  }

  &.special-event {
    .menu-list {
      display: block;
      width: 100%;

      .menu-item {
        display: block;

        img {
          margin-top: -4px;
        }
      }
    }
  }
}

// 데스크탑 마이 > 사이드네비게이션 제외한 영역
.my-main {
  //padding-top: var(--navbar-Mobile);

  @include media-breakpoint-up('lg') {
    //padding-top: var(--navbar-Desktop);
  }

  > * {
    padding-left: 1rem; // 16px gutter
    padding-right: 1rem; // 16px gutter

    @include media-breakpoint-up('md') {
      padding-left: 1.5rem; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    @include media-breakpoint-up('lg') {
      padding-left: 0; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    @include media-breakpoint-up('xlg') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-contents-wrap {
    padding-left: 0;
    padding-right: 0;

    > .section-contents {
      &:first-child {
        @include media-breakpoint-up('lg') {
          padding-left: 0;
          padding-right: 0.75rem; // 12px grid gap
        }

        @include media-breakpoint-up('xlg') {
          padding-left: 0;
          padding-right: 0.75rem; // 12px grid gap
        }
      }

      &:last-child {
        @include media-breakpoint-up('lg') {
          padding-left: 0.75rem; // 12px grid gap
          padding-right: 1.5rem; // 24px gutter
        }

        @include media-breakpoint-up('xlg') {
          padding-left: 0.75rem; // 12px grid gap
          padding-right: 0;
        }
      }
    }
  }

  // 마이페이지 아래 있는 탭 디자인 보완
  .desktop-jumbotron {
    & + .tabs {
      @include media-breakpoint-up('lg') {
        margin-right: 1.5rem;
      }

      @include media-breakpoint-up('xlg') {
        margin-right: 0;
      }
    }
  }
}

// 모바일 하단 네비게이션
.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  @include size(100%, auto);
  padding: 0 !important;
  background-color: var(--Static-White);
  z-index: var(--z-index-header);

  @include media-breakpoint-up('lg') {
    display: none;
  }

  .btn-inline-group {
    padding: 1rem 1rem 2rem 1rem;

    @include media-breakpoint-up('md') {
      padding: 1rem 1.5rem 2rem 1.5rem;
    }
  }

  .total-price {
    border-top: 1px solid var(--Border-DividerMiddle);
    display: flex;
    font-weight: 700;
    padding: 1rem 1.5rem 0 1.5rem;

    .label {
      font-size: var(--font-body-1);
      padding-top: 0.3rem;
    }

    .discount-percent {
      text-align: right;
      flex: 1;
      font-size: var(--font-heading-2);
    }

    .pricing {
      text-align: right;
      flex: 1;
      font-size: var(--font-heading-2);
    }

    .unit {
      display: inline-block;
      font-size: var(--font-heading-3);
      font-weight: 500;
      color: var(--Font-Heavy);
      padding-top: 0.1rem;
    }
  }
}

.left-mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--z-index-left-mobile-nav);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: var(--Static-White);

  .title-bar {
    display: flex;
    padding: 0 1rem;
    @include size(100%, 3.375rem);
    border-bottom: 1px solid var(--Border-DividerLight);
    background: var(--Static-White);

    .btn-close {
      @include size(1.5rem);
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .navbar-collapse {
    flex: 1;
    height: fit-content;
    width: fit-content;
    font-size: 0;

    margin-top: auto;
    margin-bottom: auto;

    &.login {
      .user-profile {
        display: flex;
        line-height: 1.5rem;
        font-size: var(--font-body-1);

        .avatar {
          @include size(1.5rem);
          margin-right: 0.5rem;
        }

        .name {
          font-weight: 700;
        }

        .arrow {
          margin-left: 0.5rem;
        }
      }
    }

    &.logout {
      padding: 0;

      > * + * {
        margin-left: 0.5rem;
      }
    }
  }

  .contents-wrap {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
  }

  .short-cut-list {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Border-DividerLight);

    .list-item {
      width: 100%;

      a {
        width: 100%;
        text-align: center;
        display: block;
      }

      .img-box {
        position: relative;
        @include size(2rem);
        display: inline-block;
      }

      img {
        @include size(2rem);
      }

      .title {
        display: block;
        font-size: var(--font-body-3);
        margin-top: 0.5rem;
      }
    }
  }

  .menu-group-list {
    border-top: 0.5rem solid var(--gray-50);
    padding-bottom: 2.5rem;
  }

  .tab-menu {
    font-weight: 700;
    padding: 1rem;

    @include background-img(1.5rem, calc(100% - 1rem) center);
    background-image: url(/assets/ic/chevron-down-bk.svg);

    border-bottom: 1px solid var(--Border-DividerLight);

    &.active {
      background-image: url(/assets/ic/chevron-up-bk.svg);

      border-bottom: none;
    }

    &.event {
      background-image: none;
      border-bottom: none;
    }

    &.best {
      background-image: none;
    }

    .new {
      padding-left: 0.25rem;
      padding-bottom: 0.75rem;
    }
  }

  .tab-menu-list {
    display: none;
    color: var(--Font-Middle);
    padding: 1rem 0.75rem;
    font-size: 0;

    background-color: var(--Background-InfoSection);
    border-bottom: 1px solid var(--Border-DividerLight);

    .menu-item {
      display: inline-block;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: var(--font-body-2);

      a {
        display: block;
      }
    }

    &.show {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.brand {
      grid-template-columns: 1fr 1fr;

      .menu-item {
        img {
          @include size(2rem);
          margin-right: 0.5rem;
        }
      }
    }
  }

  .event-item {
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    overflow: hidden;

    img {
      @include size(100%, auto);
      border-radius: var(--radius-xxs);
    }
  }

  .logout {
    padding: 2.5rem 1rem;
  }
}

// breadcrumb
.breadcrumb {
  display: none;
  padding: 2rem 0 1rem 0 !important;
  color: var(--Font-Light);

  @include media-breakpoint-up('lg') {
    display: block;
    padding: 2rem 1.5rem 1.5rem 1.5rem !important;
  }

  > * {
    display: inline-block;
    font-size: var(--font-body-3);

    + * {
      &::before {
        padding: 0.5rem;
        content: '>';
      }
    }
  }
}

// Tabs
.tabs {
  position: relative;
  display: flex;
  border-bottom: 1px solid var(--Border-DividerLight);
  justify-content: space-between;
  background-color: var(--Static-White);

  @include media-breakpoint-up('lg') {
    padding: 0 2.25rem;
  }

  &.sticky-top {
    position: sticky;
    top: var(--navbar-Mobile);
    z-index: var(--z-index-header);

    @include media-breakpoint-up('lg') {
      top: var(--navbar-DesktopClosure);
    }
  }

  .tab-item {
    width: auto;
    height: 3.375rem;
    line-height: 3.375rem;
    padding: 0 0.75rem;
    color: var(--gray-500);
    border-bottom: 2px solid transparent;

    @include media-breakpoint-up('lg') {
      margin: 0 2.5rem;
    }

    &.active {
      color: var(--gray-900);
      font-weight: 700;
      border-bottom: 2px solid var(--Border-DividerHeavy);
    }

    .new {
      margin-left: 0.25rem;
      margin-bottom: 1rem;
    }

    .number {
      font-weight: 400;
      font-size: var(--font-body-2);
    }
  }
}

// Tabs > 달력 탭 레이아웃 스타일 커스텀
.tabs-calendar {
  width: 100%;

  @include media-breakpoint-up('lg') {
    display: inline-flex;
  }

  // 기간 버튼 설정
  .btn-check-group {
    width: 100%;

    @include media-breakpoint-up('lg') {
      width: auto;
    }

    > * {
      width: 100%;

      @include media-breakpoint-up('lg') {
        width: auto;
      }
    }

    .btn-md {
      padding: 0;

      @include media-breakpoint-up('lg') {
        padding: 0 1.5rem;
      }
    }
  }

  // 기간 상세 설정
  .forms-calendar {
    margin-top: 0.5rem;

    @include media-breakpoint-up('lg') {
      margin-top: 0;
      margin-left: 0.5rem;
    }

    input {
      @include media-breakpoint-up('lg') {
        width: 10rem;
      }
    }
  }

  // 모바일 내역보기, 공통 새로고침
  .btn-inline-group {
    margin-top: 1rem;

    @include media-breakpoint-up('lg') {
      margin-top: 0;
    }

    .btn-filled {
      @include media-breakpoint-up('lg') {
        display: none;
      }
    }
  }
}

// Blocks
.section-title {
  position: relative;
  @include size(100%, 3.5rem);
  font-size: var(--font-heading-3);
  font-weight: 700;
  padding: 1rem 0;

  @include media-breakpoint-up('lg') {
    @include size(100%, 4.5rem);
    font-size: var(--font-heading-2);
    padding: 1.37rem 0;
    border-bottom: 1px solid var(--Border-DividerHeavy);
    margin-bottom: 1.5rem;
  }

  // 데스크탑에서 오른쪽 버튼이 있는 타이틀
  &.with-desktop-btn {
    .btn {
      display: none;

      @include media-breakpoint-up('lg') {
        display: block;
        position: absolute;
        top: 1.25rem;
        right: 0;
      }
    }

    .checkbox-group {
      display: none;

      @include media-breakpoint-up('lg') {
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 0;
      }
    }
  }

  // 쿠폰
  &.with-coupon {
    display: flex;

    .coupon {
      margin-left: 0.5rem;
      font-weight: 400;
      font-size: 1rem;
      padding-top: 0.0625rem;

      @include media-breakpoint-up('lg') {
        padding-top: 0.1875rem;
      }

      span {
        font-weight: 700;
      }
    }
  }

  // 포인트
  &.with-point {
    .point {
      font-size: var(--font-body-1);
      color: var(--Theme-Secondary);

      &.disabled {
        color: var(--Font-Heavy);
      }
    }

    .checkbox-group {
      position: absolute;
      top: 1rem;
      right: 0;

      @include media-breakpoint-up('lg') {
        top: 1.5rem;
      }
    }
  }

  .label {
    .value {
      margin-left: 0.25rem;
    }

    .date {
      font-size: var(--font-body-2);
      font-weight: 400;
      margin-left: 0.5rem;
      line-height: 2rem;
    }
  }

  &.mobile-title {
    @include media-breakpoint-up('lg') {
      display: none;
    }
  }
}

// 데스크탑 마이 사이드 네비게이션
.desktop-my-sidebar {
  display: none;

  @include media-breakpoint-up('lg') {
    display: block;
    //padding-top: var(--navbar-Desktop);
    margin-left: 1.5rem;
    margin-right: 3rem;
  }

  @include media-breakpoint-up('xlg') {
    margin-left: 0;
  }

  .title {
    font-size: var(--font-heading-1);
    font-weight: 700;
    padding: 2rem 0;
  }

  .my-group-list {
    .my-group-item {
      padding-bottom: 1.5rem;

      + * {
        border-top: 1px solid var(--Border-DividerLight);

        .label {
          padding: 1.5rem 0 1rem 0;
        }
      }
    }

    .label {
      padding: 1rem 0;
      font-weight: 700;

      &.link-label {
        padding-bottom: 0;
        font-weight: 400;

        &.active {
          font-weight: 700;
        }
      }
    }

    .link-item {
      display: block;
      padding: 0.38rem 0;
      color: var(--Font-Middle);

      &.active {
        font-weight: 700;
      }
    }
  }
}

// 마이 모바일 네비게이션
.my-mobile-nav {
  //padding-top: var(--navbar-Mobile);

  @include media-breakpoint-up('lg') {
    padding-top: var(--navbar-Desktop);
    display: none;
  }

  .section-contents {
    padding: 1rem;

    &.menu-contents {
      padding: 1.5rem 1rem;
    }
  }

  // 내 정보
  .my-info {
    display: flex;

    .name-col {
      flex: 1;
      line-height: 2rem;

      > * {
        vertical-align: middle;
        display: inline-block;
      }

      .avatar {
        @include size(2rem);
        margin-right: 0.5rem;
      }

      .name {
        //margin-left: 0.5rem; 아바타 숨김 보완
        font-weight: 700;
      }
    }
  }

  // 포인트, 쿠폰
  .status-group {
    display: flex;
    padding: 1rem 0;
    border-radius: var(--radius-xs);
    border: 1px solid var(--Border-DividerLight, #e4e4e7);
    margin-top: 1rem;
    margin-bottom: 1rem;

    .status-item {
      width: 100%;
      text-align: center;

      + .status-item {
        border-left: 1px solid var(--Border-DividerLight);
      }

      .value {
        font-weight: 700;
        font-size: var(--font-heading-3);
      }

      .link {
        color: var(--Font-Light, #7a7a85);
        font-size: var(--font-body-3);
      }
    }
  }

  // 모바일 마이 메뉴
  .menu-list-group {
    padding-bottom: 1rem;

    .list-item {
      padding-bottom: 1.5rem;

      + .list-item {
        padding-top: 0.5rem;
        border-top: 1px solid var(--Border-DividerLight);
      }
    }
    .label {
      padding: 0.75rem 0;
      color: var(--Font-Light, #7a7a85);
      font-size: var(--font-body-3);
    }

    .menu-group {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .menu-list {
        font-weight: 700;
        padding: 0.5rem 0;

        img {
          margin-left: 0.5rem;
          margin-top: -2px;
        }
      }
    }
  }
}

// Pill tabs
.pill-tabs {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  margin-bottom: 1.5rem;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
  }

  .list-item {
    flex: 0 0 auto;
    @include size(auto, 2.5rem);
    padding: 0 1rem;
    line-height: 2.5rem;

    font-size: var(--font-body-2);

    border-radius: var(--radius-full);
    border: 1px solid var(--Border-DividerLight);

    &.active {
      background-color: var(--Theme-Primary);
      color: var(--Static-White);
      font-weight: 700;
      border: none;
    }

    + .list-item {
      margin-left: 0.5rem;
    }

    a {
      display: block;
      @include size(100%);
    }
  }
}
