//
// Grobal
//

// Brand Font family
@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 700;
  src: url('../../font/SpoqaHanSansNeo-Bold.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 500;
  src: url('../../font/SpoqaHanSansNeo-Medium.otf');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 400;
  src: url('../../font/SpoqaHanSansNeo-Regular.otf');
}

html {
  font-family: 'Spoqa Han Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  height: 100%;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

// Variables

:root {
  // Brand Typography
  --font-heading-1: 1.5rem; // 24px
  --font-heading-2: 1.25rem; // 20px
  --font-heading-3: 1.125rem; // 18px
  --font-heading-4: 1rem; // 16px

  --font-body-1: 1rem; // 리스트 설명 타이틀, 액션
  --font-body-2: 0.875rem;
  --font-body-3: 0.75rem;

  --font-product-1: 1.75rem; // 상품소개

  // Brand Color System //TODO: 확인필요
  --gray-10: #f9f9f9;
  --gray-50: #f2f2f3;
  --gray-100: #e4e4e7;
  --gray-200: #cacace;
  --gray-300: #afafb6;
  --gray-400: #95959d;
  --gray-500: #7a7a85;
  --gray-600: #62626a;
  --gray-700: #494950;
  --gray-800: #313135;
  --gray-900: #18181b;

  --black: #000000;
  --Static-White: #ffffff;
  --dim: #00000052;

  --Theme-Teal-50: #f2fcfb;
  --Theme-Teal-100: #c8f4ed;
  --Theme-Teal-200: #9debdf;
  --Theme-Teal-300: #73e3d1;
  --Theme-Teal-400: #48dac2;
  --Theme-Teal-500: #28c7ad;
  --Theme-Teal-600: #209d89;
  --Theme-Teal-700: #177364;
  --Theme-Teal-800: #0f483f;
  --Theme-Teal-900: #061e1a;

  --Brand-Pink-50: #fef2f2;
  --Brand-Pink-100: #ffccd5;
  --Brand-Pink-200: #ff99ac;
  --Brand-Pink-300: #ff6682;
  --Brand-Pink-400: #ff3358;
  --Brand-Pink-500: #ff002f;
  --Brand-Pink-600: #cc0025;
  --Brand-Pink-700: #99001c;
  --Brand-Pink-800: #660013;
  --Brand-Pink-900: #330009;

  --Theme-Primary: var(--Theme-Teal-500);
  --Theme-Secondary: var(--Brand-Pink-500);

  --Status-error: #ef4444;
  --Status-success: #22c55e;
  --Status-warning: #f59e0b;

  // Tokens
  --Font-Heavy: var(--gray-900);
  --Font-Middle: var(--gray-700);
  --Font-Light: var(--gray-500);
  --Font-Disabled: var(--gray-300);

  --Font-ProductRatio: var(--Brand-Pink-500);

  --Border-Button: var(--gray-300);

  --Border-DividerHeavy: var(--gray-900);
  --Border-DividerMiddle: var(--gray-500);
  --Border-DividerLight: var(--gray-100);

  --Background-ButtonHovered: var(--gray-50);

  --Border-ButtonTonal: var(--Theme-Teal-500);
  --Border-ButtonTonalHovered: var(--Theme-Teal-700);
  --Background-ButtonTonal: var(--Theme-Teal-50);
  --Background-ButtonTonalHovered: var(--Theme-Teal-100);
  --Border-ButtonTonalPressed: var(--Theme-Teal-500);
  --Background-ButtonTonalPressed: var(--Theme-Teal-300);

  --Background-ButtonPressed: var(--gray-200);

  --Background-ButtonPrimaryHovered: var(--Theme-Teal-400);
  --Background-ButtonPrimaryPressed: var(--Theme-Teal-600);

  --Background-Disabled: var(--gray-50);

  --Background-Tag: var(--gray-50);
  --Background-Optional: var(--gray-50);
  --Background-SelectInfo: var(--Theme-Teal-50);
  --Background-InfoSection: var(--gray-50);

  --Background-DropdownLayer: var(--gray-10);

  // - Spacing
  --space-xxs: 0.5rem; //8px
  --space-xs: 1rem; //16px
  --space-sm: 1.5rem; //24px
  --space-md: 2rem; //32px
  --space-lg: 2.5rem; //40px
  --space-xl: 3rem; //48px
  --space-xxl: 3.5rem; //56px
  --space-3xl: 4.5rem; //72px
  --space-4xl: 5rem; //80px

  // - Radius
  --radius-xxs: 0.25rem; //4px
  --radius-xs: 0.5rem; //8px
  --radius-s: 1rem; //16px
  --radius-product: 0.75rem; //12px
  --radius-full: 60rem; //round

  // - Z-index
  --z-index-arrow: 197;
  --z-index-sticky: 198;
  --z-index-dropdown: 199;
  --z-index-header: 200;
  --z-index-header-link: 202;
  --z-index-header-menu: 210;
  --z-index-left-mobile-nav: 220;

  --z-index-modal: 300;

  // Position & Height
  --navbar-Mobile: 3.375rem;
  --navbar-DesktopClosure: 4rem;
  --navbar-MobileExpand: 6.375rem;
  --navbar-Desktop: 7.5rem;
  --navbar-Blank: 3.25rem;

  --sm-BottomNavigaion: 6rem;

  --sm-BottomCartNavigaion: 8.75rem; // 장바구니 모바일 바텀네비게이션
  --lg-BottomCartNavigaion: 5.5rem; // 장바구니 데스크탑 바텀네비게이션

  --rsbs-bg: #ffffff;
}

// Reset
*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  color: var(--Static-White);
  background: var(--Theme-Primary);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--Static-White);
  background: var(--Theme-Primary);
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1rem;
  color: var(--Font-Heavy); // TODO: 폰트컬러 확인
  text-align: left;
  background-color: var(--Static-White);
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

sub,
sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.25em;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: inherit;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button {
  background-color: inherit;
  border: 0;
  padding: 0;
  border-radius: 0;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  font-size: var(--font-body-1);
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

::placeholder {
  color: var(--Font-Light);
}

// Toggle element state
:focus {
  outline: none;
  box-shadow: 0 0 0 0 var(--Font-Heavy);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

// Brand Typography Set Up
// - heading
h1 {
  font-size: var(--font-heading-1);
  line-height: 1.75rem; /* 140% */
  letter-spacing: -0.0125rem;
}

h2 {
  font-size: var(--font-heading-2);
  line-height: 1.4;
  letter-spacing: -0.0125rem;
}

h3 {
  font-size: var(--font-heading-3);
  line-height: 1.3;
  letter-spacing: -0.01125rem;
}

h4 {
  font-size: var(--font-heading-4);
  line-height: 1.5;
  letter-spacing: -0.005rem;
}

// - body
.body-1,
p,
pre,
code,
kbd,
samp {
  font-size: var(--font-body-1);
}

.body-2,
small {
  font-size: var(--font-body-2);
}

.body-3,
sub,
sup {
  font-size: var(--font-body-3);
}

// 마이-주문배송 캘린더 이슈 보완
::-webkit-calendar-picker-indicator {
  display: none;
}
