// 홈에서 보여지는 제품 관련 스타일

.global-container {
  &.home {
    padding-top: 0;
  }
}

.home-view-action {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up('md') {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up('lg') {
    padding-left: 0;
    padding-right: 0;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up('lg') {
      width: 25rem;
    }
  }
}

.mobile-mg-r-0 {
  .home-view-action {
    padding-left: 0;
  }
}

.mobile-mg-0 {
  .product-list-header,
  .product-list {
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up('md') {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.home-text-banner {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up('lg') {
    border-radius: var(--radius-product);
  }

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: #00000059;

    color: var(--Static-White);

    .info-group {
      position: absolute;
      bottom: 0;
      padding: 1.5rem 1rem;

      @include media-breakpoint-up('md') {
        padding: 1.5rem;
      }

      @include media-breakpoint-up('lg') {
        padding: 2.5rem;
      }

      .badge {
        background-color: var(--Theme-Primary);
        padding: 0 0.75rem;
        @include size(auto, 1.5rem);
        color: var(--Static-White);
        font-weight: 700;
        border-radius: var(--radius-full);
        line-height: 1.5rem;
      }

      .title {
        font-size: var(--font-heading-2);
        font-weight: 700;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include media-breakpoint-up('lg') {
          font-size: var(--font-heading-1);
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }

      .sub-title {
        font-size: var(--font-body-2);

        @include media-breakpoint-up('lg') {
          font-size: var(--font-body-1);
        }
      }
    }
  }

  img {
    @include size(100%, auto);
    object-fit: cover;

    @include media-breakpoint-up('lg') {
      @include size(100%);
    }
  }

  &.center {
    @include size(100%, 10.25rem);
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 5rem;

    @include media-breakpoint-up('lg') {
      @include size(100%, 14rem);
    }

    .info-group {
      top: 0;
      left: 0;
      right: 0;
      margin: auto;

      height: fit-content;
    }
  }
}

.home-type-col {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 1.5rem;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up('lg') {
    grid-template-columns: 19.0629rem auto;
    grid-template-rows: auto;
  }

  @include media-breakpoint-up('xlg') {
    grid-template-columns: 34.5rem auto;
  }

  &.converse {
    @include media-breakpoint-up('lg') {
      grid-template-columns: auto 19.0629rem;
    }

    @include media-breakpoint-up('xlg') {
      grid-template-columns: auto 34.5rem;
    }

    .home-text-banner {
      @include media-breakpoint-up('lg') {
        order: 2;
      }
    }
  }

  .products {
    .product-list {
      display: flex;
      flex-wrap: wrap;

      + .product-list {
        margin-top: 1.5rem;
      }

      // 기본 3열
      > * {
        flex: 0 0 calc(33.333% - calc(2rem / 3));
        margin-bottom: 0;

        @include media-breakpoint-up('xlg') {
          flex: 0 0 calc(33.333% - calc(3rem / 3));
        }
      }

      > *:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.home-type-box {
  display: flex;
  overflow: hidden;
  position: relative;
  overflow-x: scroll;

  padding-left: 1rem;
  margin-bottom: 2.5rem;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
  }

  @include media-breakpoint-up('md') {
    padding-left: 1.5rem;
  }

  @include media-breakpoint-up('lg') {
    padding-left: 0;
  }

  .list-item {
    flex: 0 0 auto;
    width: calc((100% - 2rem) / 1.1); // 16px * 2
    padding: 1rem;
    border: 1px solid var(--Border-DividerLight);
    border-radius: var(--radius-s);

    @include media-breakpoint-up('lg') {
      flex: auto;
      padding: 1.5rem;
      width: 100%;
    }

    + .list-item {
      margin-left: 1rem;
    }
  }
}

// 홈 탑 배너
.home-promotion-banner {
  position: relative;
  overflow-x: hidden;

  padding-top: calc(var(--navbar-MobileExpand) + 0.5rem);
  margin-bottom: 1.5rem;

  @include media-breakpoint-up('lg') {
    padding-top: calc(var(--navbar-Desktop) + 0.5rem);
  }

  .swiper-slide {
    overflow: hidden;

    @include size(21.4rem);
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    border-radius: var(--radius-product);

    @include media-breakpoint-up('lg') {
      @include size(32.5rem);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .contents {
      position: absolute;

      bottom: 0;
      left: 0;

      opacity: 0;

      @include size(100%, 20rem);

      mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 1) 100%
      );

      filter: blur(25px);

      backdrop-filter: blur(25px);
    }

    .gradient {
      position: absolute;

      bottom: 0;
      left: 0;

      @include size(100%);

      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 1) 100%
      );

      filter: blur(25px);

      backdrop-filter: blur(100px);
    }

    // 인디케이터 스펙 제외 (24/12/26)
    .text-wrap {
      position: absolute;

      @include size(fit-content);
      margin: auto;
      left: 0;
      right: 0;
      bottom: 2rem; //32px

      text-align: center;
      color: var(--Static-White);

      @include media-breakpoint-up('lg') {
        bottom: 3rem; //48px
      }
    }

    .title-col {
      position: relative;
      margin-bottom: 1rem;
    }

    .title {
      position: relative;
      font-size: var(--font-heading-2);
      font-weight: 700;
      @include size(fit-content, auto);
      z-index: 1;

      margin-left: auto;
      margin-right: auto;

      opacity: 0;

      @include media-breakpoint-up('lg') {
        font-size: var(--font-heading-1);
      }
    }

    .sub-title {
      opacity: 0;
      font-size: var(--font-body-2);

      @include media-breakpoint-up('lg') {
        font-size: var(--font-body-1);
      }
    }

    .line {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;

      width: 100%;
      height: 1rem;
      opacity: 0;
      background: var(--Theme-Primary);
      z-index: -1;
    }
  }

  .swiper-slide-active {
    filter: none;

    .contents {
      opacity: 1;
      transition: opacity 0.8s ease-in;
    }

    .title,
    .sub-title {
      opacity: 1;
      transition: opacity 0.8s ease-in;
    }

    .line {
      opacity: 0.7;
      transition: opacity 0.8s ease-in;
    }

    img {
      transform: scale(1);
      transition: transform 0.8s ease;
    }
  }

  .pagenation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    margin: auto;

    display: flex;

    z-index: var(--z-index-arrow);

    font-size: var(--font-body-2);

    @include size(fit-content, 2rem);

    line-height: 2rem;

    border-radius: var(--radius-full);
    background: rgba(0, 0, 0, 0.5);

    color: var(--Static-White);
    padding: 0 1rem;

    .active {
      font-weight: 700;
    }

    .btn {
      flex: 0 0 auto;
      margin-top: -1px;
      margin-left: 0.5rem;
      @include size(1rem, 2rem);
      @include background-img(1rem, center);
      background-image: url(/assets/ic/pause.svg);

      &.play {
        background-image: url(/assets/ic/play.svg);
      }
    }
  }

  .arrow-pagenation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15.25rem;
    margin: auto;

    @include media-breakpoint-up('xlg') {
      max-width: 1128px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn-rounded {
    display: none;

    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0.25rem;
    z-index: var(--z-index-arrow);

    @include media-breakpoint-up('lg') {
      display: block;

      &.right {
        left: unset;
        right: 0.25rem;
      }
    }

    @include media-breakpoint-up('xlg') {
      left: -1.25rem;

      &.right {
        right: -1.25rem;
      }
    }
  }
}

// 홈 중간 배너
.home-img-banner {
  position: relative;
  overflow-x: hidden;

  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up('lg') {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  @include media-breakpoint-up('xlg') {
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    overflow: hidden;

    @include size(100%, auto);

    background-color: aqua;

    @include media-breakpoint-up('lg') {
      border-radius: var(--radius-xs);

      @include size(calc(50% - 12px), auto);
    }

    img {
      @include size(100%);
    }
  }

  .pagenation {
    display: block;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;

    z-index: var(--z-index-arrow);

    font-size: var(--font-body-2);

    @include size(fit-content, 1.5rem);

    line-height: 1.5rem;

    border-radius: var(--radius-full);
    background: rgba(0, 0, 0, 0.5);

    color: var(--Static-White);
    padding: 0 0.75rem;

    .active {
      font-weight: 700;
    }

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }
}

// 홈 레이어 배너
.home-layer-banner {
  position: absolute;
  //overflow-x: hidden;

  bottom: 0;

  @include media-breakpoint-up('lg') {
    margin: auto;
    top: 0;
    left: 0;
    right: 0;

    @include media-breakpoint-up('lg') {
      @include size(23rem, fit-content);
    }
  }

  .swiper-container {
    overflow-x: hidden;

    border-radius: 1rem 1rem 0 0;
  }

  .swiper-slide {
    overflow: hidden;

    @include size(100%);

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bottom-nav {
    display: flex;
    @include size(100%, 5rem);
    padding: 1rem 1rem 2rem 1.5rem;
    background-color: var(--Static-White);

    color: var(--Font-Middle);
    font-size: var(--font-body-2);

    @include media-breakpoint-up('lg') {
      @include size(100%, 4rem);
      padding: 1rem 1rem 1rem 1.5rem;

      border-radius: 0 0 1rem 1rem;
    }

    .info {
      flex: 1;
      line-height: 2rem;
    }
  }

  .arrow-pagenation {
    display: none;

    @include media-breakpoint-up('lg') {
      display: block;
    }

    .btn {
      position: absolute;
      top: 11rem;
      left: -4rem;

      z-index: var(--z-index-arrow);

      &.right {
        left: unset;
        right: -4rem;
      }
    }
  }

  .pagenation {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;

    z-index: var(--z-index-arrow);

    font-size: var(--font-body-2);

    @include size(fit-content, 1.5rem);

    line-height: 1.5rem;

    border-radius: var(--radius-full);
    background: rgba(0, 0, 0, 0.5);

    color: var(--Static-White);
    padding: 0 0.75rem;

    .active {
      font-weight: 700;
    }
  }
}
