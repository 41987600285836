//
// GrobalVariable
//

// Responsive breakpoint
// - sm (Mobile) : 375-599
// - md (Mobile l / Tablet p ) : 600-904
// - lg (Tablet L + laptop ) : 905-1199
// - xlg (Desktop) : 1200-Wide

// No media query for `sm` since this is the default in system
$breakpoints: (
  'md': (
    min-width: 600px,
  ),
  'lg': (
    min-width: 905px,
  ),
  'xlg': (
    min-width: 1200px,
  ),
);

@mixin media-breakpoint-up($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  }
}

// Image
// - Background image
@mixin background-img($bg-size, $bg-position) {
  background-size: $bg-size;
  background-position: $bg-position;
  background-repeat: no-repeat;
}

// Centered
@mixin modal-centered {
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Truncate
// - Single line truncate
@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// - Multi line truncate
@mixin multi-truncate($line-clamp) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp; // 생략할 줄 수
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
