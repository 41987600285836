// Brand footer

.footer {
  border-top: 1px solid var(--Border-DividerLight, #e4e4e7);
  background: var(--Background-Optional, #f2f2f3);

  @include media-breakpoint-up('lg') {
    margin-top: 5rem;
  }

  .col {
    padding: 1.5rem 1rem 2.5rem 1rem;

    @include media-breakpoint-up('md') {
      padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    }

    @include media-breakpoint-up('xlg') {
      padding-left: 0;
      padding-right: 0;

      max-width: 1128px; // flexible + 36px gutter
      margin-left: auto;
      margin-right: auto;
    }

    &.mobile-col {
      padding: 1.25rem 1rem;
      border-bottom: 1px solid var(--Border-DividerLight, #e4e4e7);

      @include media-breakpoint-up('md') {
        padding: 1.25rem 1.5rem;
      }

      @include media-breakpoint-up('lg') {
        display: none;
      }
    }
  }

  .link-group {
    font-size: 0;

    .link-item {
      display: inline-block;
      font-size: var(--font-body-2);
      color: var(--Font-Middle, #494950);

      // 개인정보 처리 방침(250212)
      &.bold {
        font-weight: 700;
      }

      &.desktop-item {
        display: none;

        @include media-breakpoint-up('lg') {
          display: inline-block;
        }
      }

      + .link-item {
        padding-left: 1.5rem;
      }
    }
  }

  .info-group {
    padding-top: 1.5rem;
    font-size: 0;

    .info-item {
      display: inline-block;
      font-size: var(--font-body-3);
      color: var(--Font-Light, #7a7a85);
      padding-bottom: 0.5rem;
      padding-right: 0.5rem;

      &::after {
        padding-left: 0.5rem;
        content: '|';
        color: var(--Font-Disabled);
      }

      &:last-child {
        &::after {
          padding-left: 0;
          content: '';
        }
      }

      @include media-breakpoint-up('lg') {
        display: inline-block;
      }
    }
  }

  .copyright {
    font-size: var(--font-body-3);
    color: var(--Font-Light, #7a7a85);
  }
}

// 하단 패딩 보완
.with-bottom-nav {
  + footer {
    .footer {
      padding-bottom: var(--sm-BottomNavigaion);

      @include media-breakpoint-up('lg') {
        padding-bottom: 0;
      }
    }
  }
}

.with-cart-bottom-nav {
  + footer {
    .footer {
      padding-bottom: var(--sm-BottomCartNavigaion);

      @include media-breakpoint-up('lg') {
        padding-bottom: var(--lg-BottomCartNavigaion);
      }
    }
  }
}
