// Modal

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal);
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #00000059;

  &.dark {
    background-color: var(--gray-900);
  }

  .toast {
    position: fixed;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}

.modal-center {
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--Static-White);
  width: fit-content;
  height: fit-content;
  max-width: 18rem;
  overflow: hidden;
  border-radius: var(--radius-s);
  padding: 1.5rem;
  text-align: center;
  min-width: 18rem;

  .modal-header {
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: var(--font-heading-3);
  }

  .modal-contents {
    color: var(--Font-Middle);
    padding-bottom: 2rem;
  }
}

.modal-bottom {
  position: absolute;
  margin: auto;
  bottom: 0;
  background-color: var(--Static-White);
  width: 100%;
  //height: fit-content;
  height: auto; // 모바일 디바이스에서 제대로 안나오는 현상 보완 240904
  min-height: 20rem;
  max-height: 100%;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  overflow-y: scroll;

  .modal-contents {
    position: relative;
    @include size(100%);
  }

  .handle {
    position: fixed;
    //z-index: var(--z-index-modal); NOTI: 얼럿보다 위에 있는 현상 제거
    border-radius: 1rem 1rem 0 0;
    @include size(100%, 1.5rem);
    @include background-img(2.5rem 0.25rem, center center);
    background-image: url(/assets/ic/bottom-sheets-handle.svg);
  }

  .sort-list {
    margin-top: 1.5rem;
    padding: 1rem 1.5rem;
  }

  // 구매 바텀시트
  &.for-payment {
    .modal-contents {
      padding-bottom: 8.75rem;
    }

    .dropdown {
      margin-bottom: 0;

      & + .selected-options {
        margin-top: 1rem;
      }
    }

    .dropdown-toggle {
      font-weight: 700;
    }

    .dropdown-menu {
      position: relative;
      top: -1px;
    }

    .product-option-wrap {
      padding: 2.5rem 1rem 0 1rem;

      .shipping-info {
        margin-top: 0;

        .row {
          border-top: 0;
          padding: 1.5rem 0.5rem;
        }
      }
    }

    .selected-options {
      font-size: var(--font-body-2);
      margin-bottom: 0;

      .price {
        font-size: var(--font-heading-3);
      }

      .unit {
        font-size: var(--font-body-1);
      }
    }

    .mobile-bottom-nav {
      @include media-breakpoint-up('xlg') {
        display: block;
      }
    }

    .pricing-contents {
      padding: 2.5rem 1rem 0 1rem;
      height: 20.25rem;
    }

    .pricing-group-list {
      @include size(100%, 7.8rem);
      padding: 1rem;
      background-color: var(--Background-Optional);
      border-radius: var(--radius-xs);

      .pricing-item {
        @include size(100%, auto);
        display: flex;

        + .pricing-item {
          margin-top: 0.75rem;
        }

        .label {
          font-size: var(--font-body-2);
          color: var(--Font-Heavy);
          line-height: 1.5rem;
        }

        .price {
          flex: 1;
          text-align: right;
          font-weight: 700;

          .unit {
            font-weight: 400;
            font-size: var(--font-body-2);
          }
        }
      }
    }
  }

  // 유료 배송비 추천
  &.recommend {
    @include media-breakpoint-up('xlg') {
      border-radius: 0;
    }

    .handle {
      @include media-breakpoint-up('xlg') {
        display: none;
      }
    }
    .recommend-contents {
      padding-left: 1rem;

      @include media-breakpoint-up('md') {
        padding-left: 1.5rem;
        //padding-right: 1.5rem;
      }

      @include media-breakpoint-up('lg') {
        padding-right: 1.5rem;
      }

      @include media-breakpoint-up('xlg') {
        padding-bottom: 5.5rem;
      }

      .product-list-header {
        position: relative;

        @include media-breakpoint-up('xlg') {
          max-width: 1128px;
          margin-left: auto;
          margin-right: auto;
        }

        .title {
          font-size: var(--font-heading-3);
          font-weight: 700;
          margin-bottom: 0.38rem;
        }

        .sub-title {
          font-size: var(--font-body-2);
          font-weight: 400;

          span {
            font-weight: 700;
            color: var(--Font-Heavy);
          }
        }

        .btn-close {
          display: none;

          @include media-breakpoint-up('xlg') {
            position: absolute;
            display: block;
            right: 0;
            top: 2rem;
          }
        }
      }

      .product-list-wrap {
        @include media-breakpoint-up('xlg') {
          max-width: 1128px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .to-desktop {
      @include media-breakpoint-up('xlg') {
        background-color: var(--Background-SelectInfo);
        border-top: 1px solid var(--Border-DividerHeavy);
      }

      .contents-wrap {
        @include media-breakpoint-up('xlg') {
          display: flex;
          max-width: 1128px;
          margin-left: auto;
          margin-right: auto;
        }

        .total-price {
          @include media-breakpoint-up('xlg') {
            border-top: none;
            flex: 1;
            text-align: right;
          }

          .label {
            @include media-breakpoint-up('xlg') {
              width: 100%;
              padding-top: 0.8rem;
            }
          }

          .discount-percent {
            @include media-breakpoint-up('xlg') {
              display: none;
            }
          }

          .unit {
            @include media-breakpoint-up('xlg') {
              display: none;
            }
          }
        }

        .btn-inline-group {
          @include media-breakpoint-up('xlg') {
            padding-bottom: 1rem;
            padding-right: 0;
          }
        }
      }
    }
  }

  // 쿠폰선택
  &.coupon {
    .modal-contents {
      padding-bottom: 2rem;
    }
  }

  // 1:1 문의
  &.one-on-one {
    .modal-contents {
      padding-bottom: 2rem;
    }
  }
}

// 캘린더
.calendar-modal {
  @include media-breakpoint-up('lg') {
    background-color: transparent;
  }

  .desktop-modal-center {
    @include media-breakpoint-up('lg') {
      bottom: unset;
      top: 17.81rem;
      left: 0;
      right: 0;

      width: 26.5rem;

      border-radius: 1rem;
      border: 1px solid var(--Border-DividerHeavy);
      background: var(--Static-White);
    }
  }

  .handle {
    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  .calendar-contents {
    padding: 3rem 1.5rem 3.5rem 1.5rem;

    @include media-breakpoint-up('lg') {
      padding: 1.5rem;
    }

    .contents-header {
      line-height: 1.5rem;
      margin-bottom: 1rem;
      text-align: center;

      .btn {
        display: inline-block;
        margin-bottom: 0.25rem;
      }

      .date {
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: 700;
        font-size: var(--font-heading-2);
      }
    }

    .calendar-table-wrap {
      width: 100%;

      thead {
        width: 100%;

        th {
          text-align: center;
          @include size(100%, 2rem);
          font-size: var(--font-body-3);
          color: var(--Font-Light);
          line-height: 2rem;
          font-weight: 400;
        }
      }

      tbody {
        width: 100%;

        td {
          text-align: center;
          @include size(100%, 2.5rem);
          line-height: 2.5rem;

          span,
          .date {
            display: block;
            @include size(2.5rem);
            margin-left: auto;
            margin-right: auto;
            border-radius: var(--radius-full);
          }

          .selected {
            font-weight: 700;
            color: var(--Static-White);
            background: var(--Theme-Primary);
          }

          .today {
            color: var(--Theme-Teal-600);
            background: var(--Theme-Teal-100);
          }

          .disabled {
            color: var(--Font-Disabled);
          }
        }
      }

      tr {
        display: flex;
        width: 100%;
      }
    }
  }
}

.modal-view-img {
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  .thumbnail-view {
    position: absolute;

    display: block;
    width: 100%;

    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    aspect-ratio: 1;
    object-fit: cover;
  }

  .btn-close {
    position: absolute;
    display: inline-block;
    top: 1rem;
    right: 1rem;

    > img {
      @include size(1.5rem);
    }
  }
}

.modal-fluid {
  position: relative;
  @include size(100%); // 본인인증 버튼 미노출 보완
  overflow: hidden;
  background-color: var(--Static-White);

  @include media-breakpoint-up('lg') {
    position: absolute;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    border-radius: 1.5rem;
    @include size(23.475rem, 740px);
    min-height: 34rem;
    max-height: 46.25rem;
  }

  .banner {
    height: auto;
    width: 100%;
  }

  .modal-fluid-nav {
    display: flex;
    @include size(100%, 3.375rem);
    font-weight: 700;
    padding: 0 1rem;
    border-bottom: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('lg') {
      @include size(100%, 4.5rem);
      font-size: var(--font-heading-3);
      padding: 0 1.5rem;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-top: 0.94rem;

      @include media-breakpoint-up('lg') {
        margin-top: 1.5rem;
      }
    }

    .btn-close {
      display: block;
      margin-top: 0.94rem;

      @include media-breakpoint-up('lg') {
        padding-top: 1.3rem;
        margin-top: 0;
      }

      > img {
        @include size(1.5rem);
      }
    }
  }

  .modal-contents {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;

    @include size(100%, calc(100dvh - 3.375rem - 6rem));
    // 동적인 뷰포트값 처리

    // 12.3125rem
    overflow: hidden;
    overflow-y: scroll;

    @include media-breakpoint-up('lg') {
      @include size(100%, calc(740px - 4.5rem - 6rem)); // 가상 높이값 변경
    }

    .row {
      @include size(100%, auto);
      padding: 1.5rem 1rem 1.5rem 1rem;

      @include media-breakpoint-up('lg') {
        padding: 1.5rem;
      }

      &.main {
        flex: 1;

        > * + * {
          margin-top: 1rem;
        }
      }

      &.caution {
        font-size: var(--font-body-2);
        color: var(--Font-Light);

        .with-icon {
          display: block;
          padding-left: calc(1.125rem + 0.25rem);
          @include background-img(1.125rem, 0 0);
          background-image: url(/assets/ic/info-gr.svg);
        }

        a {
          text-decoration: underline;
        }
      }

      .empty-list {
        padding-top: 3.5rem;
      }

      // 1회 본인인증
      &.terms-check {
        padding: 0;

        .outer-list {
          border-top: 1px solid var(--Border-DividerLight);
          padding: 1.5rem 1rem;

          @include media-breakpoint-up('lg') {
            padding: 1.5rem;
          }

          .list-item {
            position: relative;

            + .list-item {
              margin-top: 0.75rem;
            }

            .terms-link {
              position: absolute;
              top: 0.15rem;
              right: 0;
              color: var(--Font-Light, #7a7a85);
              text-decoration: underline;
              font-size: var(--font-body-3);
            }
          }

          .inner-list {
            padding-left: 1.6rem;

            .list-item {
              display: inline-block;

              + .list-item {
                margin-top: 0.3rem;
                margin-left: 1.25rem;
              }
            }

            .info {
              font-size: var(--font-body-3);
              color: var(--Font-Light);
            }
          }
        }
      }

      .certification-wrap {
        .title-row {
          font-weight: 700;
          font-size: var(--font-heading-2);
          margin-bottom: 1.5rem;
        }

        .img-row {
          text-align: center;
        }
      }
    }

    .reviews {
      text-align: center;
      margin-bottom: 1.5rem;

      .review-point {
        font-size: var(--font-heading-3);
        font-weight: 700;
        margin-bottom: 1rem;

        span {
          font-size: var(--font-body-2);
          font-weight: 400;
          display: inline-block;
          margin-left: 0.25rem;
        }
      }

      .stars {
        > img {
          @include size(2.5rem);

          & + * {
            margin-left: 0.25rem;
          }
        }
      }
    }

    .img-uploaded {
      position: relative;
      @include size(7.5rem);
      border-radius: var(--radius-xs);
      border: 1px solid rgba(0, 0, 0, 0.08);
      overflow: hidden;

      > img {
        @include size(7.5rem);
        object-fit: cover;
      }
    }

    .btn-delete {
      position: absolute;
      right: 0.3rem;
      top: 0.3rem;
      display: block;
      @include size(1.5rem);

      > img {
        @include size(1.5rem);
      }
    }

    .badge-payment {
      margin-bottom: 0.5rem;
    }
  }

  .mobile-bottom-nav {
    position: absolute;

    @include media-breakpoint-up('lg') {
      display: block;
    }
  }

  // 주소관련 모달에서만 너비 다름
  &.modal-address {
    @include media-breakpoint-up('lg') {
      width: 30rem;
    }

    .search-example {
      font-size: var(--font-body-2);
      list-style: disc;
      padding-left: 1rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      li {
        margin-bottom: 0.5rem;
      }

      span {
        color: var(--Font-Light);
      }
    }

    .search-title {
      font-weight: 700;
      padding: 1rem 0;
      border-bottom: 1px solid var(--Border-DividerHeavy);
    }

    .empty-list {
      border-bottom: 1px solid var(--Border-DividerLight);

      + .search-example {
        border-bottom: 1px solid var(--Border-DividerLight);
      }
    }

    .address-list-group {
      font-size: var(--font-body-2);

      .list-item {
        padding: 1.5rem 0;

        + .list-item {
          border-top: 1px solid var(--Border-DividerLight);
        }

        &:last-child {
          border-bottom: 1px solid var(--Border-DividerLight);
        }
      }

      .prev-address {
        color: var(--Font-Light);
        margin-top: 0.5rem;
      }

      .forms-group {
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }

  // 취소,반품 상품 선택
  .product-select {
    .product-item {
      padding: 1.5rem 0;

      + .product-item {
        border-top: 1px solid var(--Border-DividerLight);
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .checkbox-group {
      &.sm-box {
        padding-right: 0.5rem;
      }
    }

    .pricing {
      margin-top: 0.5rem;
    }

    .prev-price {
      font-size: var(--font-body-2);
    }
  }
}

// 모달에서 사용하는 유의사항
// 유의사항
.modal-notice {
  width: 100%;
  padding: 1.5rem 1rem 2.5rem 1rem;

  border-top: 0.5rem solid var(--gray-50);

  font-size: var(--font-body-2);
  color: var(--Font-Light);

  @include media-breakpoint-up('lg') {
    border-top: none;

    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  }

  .title {
    @include background-img(1.125rem, 0 1px);
    background-image: url(/assets/ic/info-gr.svg);
    padding-left: 1.25rem;
    margin-bottom: 1.5rem;

    &.line {
      text-decoration: underline;
    }
  }

  .list-group {
    list-style-type: disc;
    padding-left: 1.25rem;

    li {
      // 부분취소 환불규정 강조(250213)
      &.notice {
        color: var(--Theme-Secondary);
      }

      + * {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    text-decoration: underline;
  }
}

// 부분취소 환불규정 강조(250213)
.modal-cancel-info {
  padding-top: 1.5rem;
  border-top: 1px solid var(--Border-DividerLight);

  .title {
    font-weight: 700;
    margin-bottom: 1rem;

    .category {
      color: var(--Theme-Secondary);
      margin-left: 0.75rem;
    }
  }

  .info {
    font-size: 0.875rem;
    color: var(--font-body-2);
  }

  .notice {
    font-size: 0.875rem;
    color: var(--Theme-Secondary);
  }
}

// Loading Indicator
.loading-indicator {
  position: relative;
  @include size(100%);

  .loading-indicator-img {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}

.for-payment {
  .modal-contents {
    padding-bottom: 8.75rem;
  }

  .dropdown {
    margin-bottom: 0;

    & + .selected-options {
      margin-top: 1rem;
    }
  }

  .dropdown-toggle {
    font-weight: 700;
  }

  .dropdown-menu {
    position: relative;
    top: -1px;
  }

  .product-option-wrap {
    padding: 0.5rem 1rem 0 1rem;

    .shipping-info {
      margin-top: 0;

      .row {
        border-top: 0;
        padding: 1.5rem 0.5rem;
      }
    }
  }

  .selected-options {
    font-size: var(--font-body-2);
    margin-bottom: 0;

    .price {
      font-size: var(--font-heading-3);
    }

    .unit {
      font-size: var(--font-body-1);
    }
  }

  .pricing-contents {
    padding: 2.5rem 1rem 0 1rem;
    height: 20.25rem;
  }

  .pricing-group-list {
    @include size(100%, 7.8rem);
    padding: 1rem;
    background-color: var(--Background-Optional);
    border-radius: var(--radius-xs);

    .pricing-item {
      @include size(100%, auto);
      display: flex;

      + .pricing-item {
        margin-top: 0.75rem;
      }

      .label {
        font-size: var(--font-body-2);
        color: var(--Font-Heavy);
        line-height: 1.5rem;
      }

      .price {
        flex: 1;
        text-align: right;
        font-weight: 700;

        .unit {
          font-weight: 400;
          font-size: var(--font-body-2);
        }
      }
    }
  }
}
.handle {
  border-radius: 1rem 1rem 0 0;
  @include size(100%, 1.5rem);
  @include background-img(2.5rem 0.25rem, center center);
  background-image: url(/assets/ic/bottom-sheets-handle.svg);
}

// 모바일 bottom 에만 적용되는 style
#spring-modal {
  .product-option-wrap {
    padding: 2.5rem 1rem 0 1rem;

    .shipping-info {
      margin-top: 0;

      .row {
        border-top: 0;
        padding: 1.5rem 0.5rem;
      }
    }
  }
}
