// 제품 상세 정보

// 제품 상단 정보
.product-detail-top {
  position: relative;
  @include size(100%);
  padding: 0;

  @include media-breakpoint-up('lg') {
    display: flex;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
  }

  @include media-breakpoint-up('xlg') {
    padding: 2.25rem 0 2rem 0;
  }

  .thumbnail-col {
    position: relative;
    @include size(100%);
    //aspect-ratio: 1; 데스크탑 다수 썸네일 영역이 안잡히는 상태 개선

    @include media-breakpoint-up('lg') {
      position: sticky;
      top: var(--navbar-Desktop);
    }

    .thumbnail-mobile-pagenation {
      position: absolute;
      margin: auto;

      bottom: 1rem;
      right: 0;
      left: 0;

      @include size(fit-content, 2rem);
      padding: 0 1rem;
      line-height: 2rem;
      font-size: var(--font-body-3);

      color: var(--Static-White);
      border-radius: 62.4375rem;
      background: rgba(0, 0, 0, 0.35);

      @include media-breakpoint-up('lg') {
        display: none;
      }

      .active {
        font-weight: 700;
      }
    }

    .thumbnail-desktop-group {
      display: none;
      margin-top: 1rem;
      width: 100%;
      font-size: 0;

      @include media-breakpoint-up('lg') {
        display: block;
      }

      .list-item {
        display: inline-block;
        @include size(2.95rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0.375rem;
        overflow: hidden;

        border: 1px solid var(--Border-DividerLight);

        &:last-child {
          margin-right: 0;
        }

        a {
          @include size(100%);
          display: block;
        }

        img {
          @include size(100%);
          object-fit: cover;
        }

        &.active {
          border: 1px solid var(--Border-DividerHeavy);
        }
      }
    }
  }

  // 썸네일 영역
  .thumbnail {
    position: relative;
    @include size(100%);
    aspect-ratio: 1;
    overflow: hidden;

    .swiper-container {
      min-width: 100%;

      .swiper-wrapper {
        min-width: 100%;
        display: flex;
        flex-direction: row;

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            @include size(100vw);
            display: block;
            object-fit: cover;
          }
        }
      }
    }

    img {
      @include size(100%);
      object-fit: cover;
    }

    @include media-breakpoint-up('lg') {
      border: 1px solid rgba(0, 0, 0, 0.04);
      border-radius: var(--radius-product);
    }

    .thumbnail-dim {
      position: absolute;
      @include size(100%);
      top: 0;
      left: 0;
      background-color: var(--dim);

      @include background-img(7.5rem, center center);
      background-image: url(/assets/ic/badge-soldout.svg);

      // 모바일 품절표시 (250214)
      z-index: 1;
    }

    // 오픈 예정 (250214)
    .thumbnail-preview {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0 16px;
      @include size(auto, 40px);

      font-size: var(--font-body-2);
      font-weight: 700;
      background-color: var(--Theme-Primary);
      color: var(--Static-White);

      line-height: 40px;

      z-index: 1;

      @include media-breakpoint-up('lg') {
        @include size(100%, 40px);
        top: unset;
        bottom: 0;
      }

      img {
        display: inline-block;
        @include size(20px);
        margin-right: 4px;
        margin-top: -3px;
      }
    }
  }

  // 정보 영역
  .information {
    @include size(100%);
    padding: 1.5rem 1rem;

    @include media-breakpoint-up('md') {
      padding: 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      padding: 0;
      margin-top: 0;
      margin-left: 2.5rem;
    }

    .title-wrap {
      @include media-breakpoint-up('lg') {
        padding-right: 4.75rem;
      }

      .store {
        display: block;
        font-size: var(--font-body-2);
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.00438rem;
        margin-bottom: 1rem;

        &::after {
          // content: ' >';
        }

        @include media-breakpoint-up('lg') {
          font-size: var(--font-body-1);
          font-weight: 400;
          line-height: 1.5rem; /* 150% */
        }
      }

      .title {
        font-size: var(--font-heading-2);
        font-weight: 500;
        line-height: 1.75rem; /* 140% */
        letter-spacing: -0.0125rem;

        margin-bottom: 0.75rem;

        @include media-breakpoint-up('lg') {
          font-size: var(--font-heading-1);
          line-height: 2rem; /* 140% */
        }
      }

      .review-group {
        display: flex;
        margin-top: var(--space-xs);
        margin-bottom: var(--space-xs);

        * + * {
          margin-left: 0.75rem;
        }

        .review-point {
          font-size: var(--font-body-2);
          font-weight: 700;
          padding-left: 1.25rem;
          @include background-img(1rem, 0 center);
          background-image: url(/assets/ic/star.svg);
          letter-spacing: -0.00438rem;
          line-height: 1.5;

          @include media-breakpoint-up('xlg') {
            @include background-img(1.5rem, 0 center);
            font-size: var(--font-heading-3);
            padding-left: 1.75rem;
          }
        }

        .reviews {
          &::before {
            content: '상품평 ';
          }

          font-size: var(--font-body-3);
          color: var(--Font-Middle);
          text-decoration: underline;
          line-height: 1.75rem;

          @include media-breakpoint-up('lg') {
            font-size: var(--font-body-2);
          }
        }
      }
    }

    .pricing-wrap {
      display: flex;

      font-size: var(--font-heading-1);

      * + * {
        margin-left: var(--space-xxs);
      }

      .prev-label {
        font-weight: 700;
        font-size: var(--font-heading-2);
        color: var(--Font-Disabled);
      }

      .prev-price {
        font-size: var(--font-heading-2);
        margin-top: 0.125rem;
      }
    }

    .with-code {
      .discount-percent {
        margin-top: 2px;
      }

      .pricing {
        margin-top: 2px;
      }

      .prev-price {
        font-size: var(--font-heading-1);
        margin-top: 0;
      }

      .prev-label {
        line-height: 2.25rem;
      }
    }

    .with-avatar {
      margin-top: 1rem;

      .code-name {
        height: fit-content;
        margin-top: auto;
        margin-bottom: auto;
        font-size: var(--font-body-2);
        padding-right: 0.75rem;
        border-right: 1px solid var(--Border-DividerLight);
        margin-right: 0.75;

        @include media-breakpoint-up('md') {
          font-size: var(--font-body-1);
        }
      }
    }

    .callout {
      margin-top: var(--space-sm);
    }
  }

  // 문의/공유 영역
  .interaction {
    display: flex;
    border-top: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('lg') {
      position: absolute;
      top: 2.5rem;
      right: 1.5rem;
      border: none;
    }

    @include media-breakpoint-up('xlg') {
      right: 0;
    }

    > * {
      display: block;
      @include size(100%);
      text-align: center;
      padding: 1rem 0;

      @include media-breakpoint-up('lg') {
        padding: 0;
      }

      + * {
        border-left: 1px solid var(--Border-DividerLight);

        @include media-breakpoint-up('lg') {
          border: none;
          margin-left: 0.75rem;
        }
      }

      img {
        display: inline-block;
        @include size(1.5rem);
        margin: -0.2rem 0.5rem 0 0;

        @include media-breakpoint-up('lg') {
          display: block;
          margin: 0 0 0.5rem 0;
        }
      }

      span {
        display: inline-block;
        height: 1.5rem;
        line-height: 1.5rem;
        font-size: var(--font-body-1);

        @include media-breakpoint-up('lg') {
          height: 1rem;
          line-height: 1rem;
          display: block;
          font-size: var(--font-body-3);
        }
      }
    }

    // 폐쇄몰 스타일 추가
    .mobile-icon {
      @include media-breakpoint-up('lg') {
        display: none;
      }
    }
  }

  // 데스크탑에서 정보영역 일부 감추기
  .product-option-wrap {
    display: none;

    @include media-breakpoint-up('lg') {
      display: block;
      margin-top: 2rem;
    }
  }
}

// 제품 중앙 정보
.product-detail-middle {
  position: relative;
  padding: 0;

  .tabs {
    padding: 0 1rem;
  }

  @include media-breakpoint-up('xlg') {
    display: flex;
    border-top: 1px solid var(--Border-DividerHeavy);
    border-bottom: 1px solid var(--Border-DividerLight);
  }

  .wide-col {
    @include media-breakpoint-up('xlg') {
      width: 48.125rem;
    }
  }

  .narrow-col {
    display: none;

    @include media-breakpoint-up('xlg') {
      display: block;

      border-left: 1px solid var(--Border-DividerLight);
      width: 22.375rem;
    }
  }

  .product-option-wrap {
    padding: 1.5rem;
    position: sticky;
    top: var(--navbar-Desktop);
    z-index: var(--z-index-sticky);

    .total-price {
      .label {
        font-size: var(--font-body-1);
        padding-top: 0.4rem;
      }

      .discount-percent {
        font-size: var(--font-heading-1);
      }

      .unit {
        font-size: var(--font-heading-2);
        font-weight: 400;
      }
    }
  }
}

// 상품 탭 영역 + 하위 정보
.tabs-contents {
  display: none;
  padding: 1.5rem 1rem 2.5rem 1rem;

  > img {
    @include size(100%, auto);
  }

  @include media-breakpoint-up('md') {
    padding: 1.5rem;
  }

  @include media-breakpoint-up('lg') {
    display: block;
    padding: 1.5rem;
  }

  @include media-breakpoint-up('xlg') {
    padding: 2.5rem 1.5rem 2.5rem 0;
  }

  &.detail {
    position: relative;
    margin-bottom: 2.5rem;
    @include size(100%, auto);
    overflow: hidden;

    .pagenation-group {
      position: absolute;
      @include size(100%, auto);
      left: 0;
      bottom: 0;
      padding: 0.5rem 1.5rem;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 90%);

      @include media-breakpoint-up('xlg') {
        padding: 0.5rem 2.5rem 0.5rem 1rem;
      }
    }
  }

  &.reviews {
    .notice {
      position: relative;
      padding: 0.75rem 1rem;
      background-color: var(--gray-50);
      border-radius: var(--radius-xs);
      margin-bottom: 1rem;

      .btn-subtle {
        padding-left: 0;
        //text-decoration: underline;
        img {
          @include size(1.125rem);
        }
      }

      .btn-outline {
        float: right;
      }
    }

    .graphs {
      text-align: center;
      padding: 2rem 2.5rem;
      border: 1px solid var(--Border-DividerLight);
      border-radius: var(--radius-xs);
      font-weight: 700;

      @include media-breakpoint-up('xlg') {
        display: flex;
      }

      .col {
        height: fit-content;
        margin-top: auto;
        margin-bottom: auto;

        & + .col {
          width: fit-content;
          margin-top: 2.5rem;
          margin-left: auto;
          margin-right: auto;

          @include media-breakpoint-up('xlg') {
            margin-top: 0;
          }
        }
      }

      .score {
        font-size: 2.5rem;
      }

      .stars {
        margin-bottom: 1.5rem;
        font-size: 0;

        img {
          display: inline-block;
          @include size(1.5rem);

          & + * {
            margin-left: 0.25rem;
          }
        }
      }

      .bar-group {
        display: inline-block;
        font-weight: 400;
        width: 2.5rem;

        & + * {
          margin-left: 1rem;
        }

        .state {
          font-size: var(--font-body-3);
          color: var(--Font-Light);
        }

        .bar-gutter {
          position: relative;
          @include size(0.75rem, 5rem);
          margin: 0.5rem auto;
          border-radius: 2rem 2rem 0 0;
          background-color: var(--gray-100);
          overflow: hidden;

          .bar {
            position: absolute;
            bottom: 0;
            left: 0;
            border-radius: 2rem 2rem 0 0;
            @include size(100%, auto);
            background-color: var(--Theme-Primary);
          }
        }
      }
    }

    .pagenation-group {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .section-title {
    display: none;

    @include media-breakpoint-up('xlg') {
      display: block;
    }
  }

  &.products {
    .product-list {
      &.vertical {
        > * {
          // xlg에서 4열
          @include media-breakpoint-up('xlg') {
            flex: 0 0 calc(25% - calc(4.5rem / 4));
            //width: calc((100% - 3rem) / 4); // 16px * 3
            margin-right: 1.5rem;

            &:nth-of-type(3n) {
              margin-right: 1.5rem;
            }

            &:nth-of-type(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }

    .info-img {
      img {
        @include size(100%, auto);
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }

  &.info {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.5rem;

    @include media-breakpoint-up('lg') {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    // 상품정보 이미지 영역
    .info-img-wrap {
      padding: 1.5rem 1.5rem 2.5rem 1.5rem;

      img {
        display: block;
        @include size(100%, auto);
      }
    }

    // 상품정보 테이블 영역
    .info-accordion-wrap {
      border-top: 0.5rem solid #f5f5f5;

      @include media-breakpoint-up('lg') {
        margin-top: 1.5rem;
        border-top: 0;
        border-radius: var(--radius-product);
        border: 1px solid var(--Border-DividerLight);
      }

      // 아코디언 그룹
      .accordion-group {
        > .list-item {
          + .list-item {
            border-top: 1px solid var(--Border-DividerLight);
          }

          &:last-child {
            border-bottom: 1px solid var(--Border-DividerLight);

            @include media-breakpoint-up('lg') {
              border-bottom: none;
            }
          }

          .title {
            padding: 1rem;
            @include background-img(1.5rem, calc(100% - 1rem) 1rem);
            background-image: url(/assets/ic/chevron-down-bk.svg);

            @include media-breakpoint-up('lg') {
              padding: 1.5rem;
              @include background-img(1.5rem, calc(100% - 1.5rem) 1.5rem);
            }
          }

          &.active {
            .panel-group {
              display: block;
            }

            .title {
              background-image: url(/assets/ic/chevron-up-bk.svg);
            }
          }
        }
      }

      // 감춰지는 영역
      .panel-group {
        display: none;
        font-size: var(--font-body-2);
        color: var(--Font-Middle);
        padding-bottom: 2.5rem;

        > .list-item {
          padding-left: 1rem;
          padding-right: 1rem;

          &:first-child {
            .row {
              @include media-breakpoint-up('lg') {
                border-top: 1px solid var(--Border-DividerLight);
              }

              &:first-child {
                border-top: 1px solid var(--Border-DividerLight);
              }
            }
          }

          &.notice {
            margin-top: 1.5rem;
            @include background-img(1.25rem, 1rem 0);
            padding-left: 2.25rem;
            background-image: url(/assets/ic/dot.svg);
            color: var(--Font-Light, #7a7a85);

            + .notice {
              margin-top: 0.5rem;
            }
          }

          @include media-breakpoint-up('lg') {
            display: flex;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          .row {
            display: flex;
            border-left: 1px solid var(--Border-DividerLight);
            border-right: 1px solid var(--Border-DividerLight);
            border-bottom: 1px solid var(--Border-DividerLight);

            @include media-breakpoint-up('lg') {
              width: 100%;
              border-right: none;
            }

            &:nth-child(2n) {
              //border-top: none;

              @include media-breakpoint-up('lg') {
                //border-top: 1px solid var(--Border-DividerLight);
                border-right: 1px solid var(--Border-DividerLight);
              }
            }

            .col {
              width: 100%;
              padding: 0.75rem;
              align-content: space-evenly;

              @include media-breakpoint-up('lg') {
                padding: 1rem 0.75rem;
              }

              &.header {
                width: 100%;
                border-right: 1px solid var(--Border-DividerLight);
                background: var(--Background-InfoSection);
              }
            }
          }
        }
      }
    }
  }
}

// 상품 옵션(공통)
.product-option-wrap {
  .dropdown {
    margin-bottom: 1rem;
  }

  .selected-options {
    margin-bottom: 1rem;

    > * {
      padding: 1rem 1.5rem;
      position: relative;
      background-color: #f5f5f5;
      border-radius: var(--radius-xs);

      & + * {
        margin-top: 0.5rem;
      }
    }

    .row {
      display: flex;
      height: auto;

      + .row {
        height: 2.5rem;
        line-height: 2.5rem;
        margin-top: 0.5rem;
      }
    }

    .title {
      margin-left: 0.5rem;
      margin-right: 1rem;
      padding-right: 1rem;
    }

    .price {
      flex: 1;
      text-align: right;
      font-size: var(--font-heading-2);
      font-weight: 700;
      letter-spacing: -0.0125rem;
    }

    .unit {
      display: inline-block;
      font-size: var(--font-heading-3);
      font-weight: 400;
    }

    .btn-delete {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
    }

    .discount-percent {
      margin-right: 0.75rem;
    }
  }

  .total-price {
    border-top: 1px solid var(--Border-DividerMiddle);
    padding: 1.5rem;
    display: flex;
    font-weight: 700;

    .label {
      font-size: var(--font-heading-3);
      padding-top: 0.6rem;
    }

    .discount-percent {
      text-align: right;
      flex: 1;
      font-size: var(--font-product-1);
      align-items: center; /* 수직 가운데 정렬 */
    }

    .unit {
      display: inline-block;
      font-weight: 500;
      font-size: var(--font-heading-1);
      color: var(--Font-Heavy);
      padding-top: 0.2rem;
    }
  }

  .shipping-info {
    margin-top: 2rem;
    font-size: var(--font-body-2);
    color: var(--Font-Middle);
    border-bottom: 1px solid var(--Border-DividerLight);

    .header {
      padding-right: 1.5rem;
    }

    .row {
      display: flex;
      border-top: 1px solid var(--Border-DividerLight);
      padding: 1rem 0.5rem;
    }

    .contents {
      * + * {
        margin-top: 0.5rem;
      }
    }
  }
}

// 상품 리뷰(공통)
.review-list {
  > * {
    position: relative;
    padding: 2.5rem 0;

    & + * {
      border-top: 1px solid var(--Border-DividerLight);
    }
  }

  .report {
    position: absolute;
    top: 2.5rem;
    right: 0;
    text-decoration: underline;
    color: var(--Font-Light);
    font-size: var(--font-body-2);
    line-height: 1.5rem;
  }

  .user-info {
    display: flex;
    margin-bottom: 1.5rem;

    .avatar {
      margin-right: 0.75rem;
    }

    .info {
      flex: 1;
      padding-right: 2.5rem;
    }

    .stars {
      display: flex;
      @include size(100%, 1.125rem);
      vertical-align: middle;

      > img {
        @include size(1rem);
      }

      .score {
        font-size: var(--font-body-2);
        font-weight: 700;
        line-height: 1.125rem;
        margin-left: 0.5rem;
      }
    }

    .usertext {
      color: var(--Font-Light);
      font-size: var(--font-body-2);

      > * {
        & + * {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .title {
    margin-top: 1rem;
    color: var(--Font-Middle);
    font-size: var(--font-body-2);
    margin-bottom: 0.5rem;

    > * {
      & + * {
        margin-left: 0.5rem;
      }
    }
  }

  .review-img {
    @include size(7.5rem);
    border-radius: var(--radius-xs);
    margin-top: 1.5rem;
    border: 1px solid var(--Border-DividerLight);
    object-fit: cover;

    &.desktop-lg {
      @include media-breakpoint-up('xlg') {
        @include size(23rem);
      }
    }
  }
}

// 폐쇄몰 스타일 보완
.global-container {
  &.closure {
    .product-detail-top {
      .thumbnail-col {
        @include media-breakpoint-up('lg') {
          top: var(--navbar-DesktopClosure);
        }
      }
    }

    .tabs {
      &.sticky-top {
        @include media-breakpoint-up('lg') {
          top: var(--navbar-DesktopClosure);
        }
      }
    }

    .product-option-wrap {
      @include media-breakpoint-up('lg') {
        top: var(--navbar-DesktopClosure);
      }
    }
  }
}

// 폐쇄몰 약사 정보
.closure-code-info {
  display: flex;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up('lg') {
    line-height: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  .avatar {
    @include size(2rem);

    @include media-breakpoint-up('lg') {
      @include size(3rem);
    }
  }

  .code-name {
    padding-left: 0.75rem;
    font-weight: 700;
    font-size: var(--font-heading-3);

    @include media-breakpoint-up('lg') {
      font-size: var(--font-heading-1);
    }
  }
}
