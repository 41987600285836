// Tooltip

.tooltip {
  position: absolute;
  @include size(18rem, auto);
  padding: 1rem 2.5rem 1rem 1rem;

  background-color: var(--Static-White);
  border: 1px solid var(--Border-DividerMiddle);
  font-size: var(--font-body-3);
  border-radius: var(--radius-xs);

  z-index: var(--z-index-modal);

  box-shadow: 0px 4px 12px 0px rgba(44, 53, 88, 0.08);
  display: flex;
  flex-flow: column;
  gap: 12px;
  .title {
    font-weight: 700;
    padding-bottom: 0.25rem;
  }

  .contents {
    color: var(--Font-Middle);
  }

  .ic-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
