// Toast

.toast {
  position: fixed;
  display: block;
  font-size: var(--font-body-1);
  @include size(fit-content);
  padding: 0.5rem 1rem;
  border-radius: var(--radius-xs);
  color: var(--Static-White);
  box-shadow: 0px 4px 8px 0px rgba(44, 53, 88, 0.08);
  @include background-img(1.5rem, 1rem center);
  font-weight: 700;
  z-index: var(--z-index-modal);
  background: var(--Greyscale-600, #62626a);

  // 토스트 위치 미정
  top: 1rem;
  right: 0;
  left: 0;
  margin: auto;

  &.warning {
    @include background-img(1.5rem, 1rem center);
    background-color: var(--Status-warning);
    background-image: url('/assets/ic/info-wt.svg');
  }

  &.checked {
    @include background-img(1.5rem, 0.5rem center);
    background-image: url('/assets/ic/checked-wt.svg');
    padding: 1rem 1rem 1rem 2.25rem;
  }

  &.marketing {
    padding: 1rem;
    text-align: center;
    width: 19rem;

    .sub-text {
      margin-top: 0.5rem;
      font-weight: 400;
      font-size: var(--font-body-2);
    }
  }
}
