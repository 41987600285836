// 마이 디폴트 페이지

// 데스크탑에서만 나오는 영역
.desktop-my-default {
  display: none;

  @include media-breakpoint-up('lg') {
    display: block;
  }

  margin-top: 1.5rem;

  .my-info {
    display: flex;
    padding: 1.5rem;

    border-radius: var(--radius-xs);
    border: 1px solid var(--Border-DividerLight);

    .name-col {
      flex: 1;
      line-height: 2rem;

      > * {
        vertical-align: middle;
        display: inline-block;
      }

      .avatar {
        @include size(3rem);
      }

      .name {
        margin-left: 0.5rem;
        font-weight: 700;
      }
    }

    .btn-outline {
      margin-top: 0.5rem;
    }
  }

  .desktop-jumbotron {
    padding-top: 0;
    margin-bottom: 1.5rem;
  }

  .list-action {
    margin-top: 2.5rem;
    text-align: center;

    .btn {
      width: 25rem;
    }
  }
}
