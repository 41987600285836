// 이메일 로그인

.login-contents-wrap {
  padding: 1.5rem 1rem;

  @include media-breakpoint-up('md') {
    padding: 1.5rem;
  }

  @include media-breakpoint-up('lg') {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;
  }

  .action-group {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &.desktop-action-group {
      display: none;

      @include media-breakpoint-up('lg') {
        display: block;
      }
    }

    &.inline {
      display: flex;

      > * + * {
        margin-left: 1rem;
      }
    }
  }

  .login-action {
    @include size(100%, 3rem);
    border: 1px solid var(--Border-Button);
    border-radius: var(--radius-xs);
    line-height: 3rem;

    @include media-breakpoint-up('lg') {
      @include size(100%, 3.5rem);
    }

    img {
      @include size(1.5rem);
      margin-top: -0.25rem;
      margin-right: 0.5rem;
    }

    &.kakao {
      font-weight: 700;
      background: #ffe401;
      border: 1px solid #ffe401;
      // 카카오 로그인 버튼 텍스트 컬러 보정
      color: #18181b;
    }

    &.apple {
      font-weight: 700;
      background: var(--black);
      border: 1px solid var(--black);
      // 카카오 로그인 버튼 텍스트 컬러 보정
      color: var(--Static-White);
    }

    + .login-action {
      margin-top: 1rem;
    }
  }

  .action-divider {
    position: relative;
    margin-top: 1.59rem;
    border-top: 1px solid var(--Border-DividerLight);
    color: var(--Font-Light, #7a7a85);
    font-size: var(--font-body-2);
    padding-bottom: 3rem;

    .label {
      @include size(fit-content);
      display: inline-block;
      position: absolute;
      padding: 0 1rem;
      top: -0.65rem;
      left: 0;
      right: 0;
      margin: auto;
      background-color: var(--Static-White);
    }
  }

  .login-forms-contents {
    .input-group {
      + .input-group {
        margin-top: 0.5rem;

        &.checkbox-input-group {
          margin-top: 1rem;
        }
      }
    }
  }

  .link-group {
    text-align: center;

    .btn-link {
      margin-top: -0.25rem;
      color: var(--Font-Light, #7a7a85);
      font-size: var(--font-body-2);

      @include media-breakpoint-up('lg') {
        font-size: var(--font-body-1);
      }
    }

    .divider {
      display: inline-block;
      height: 1rem;
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 1px solid var(--Font-Disabled);
    }
  }

  .result-list-group {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    padding: 1.5rem 0.5rem;

    border-top: 1px solid var(--Border-DividerLight);
    border-bottom: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('lg') {
      padding: 1.5rem;
      margin-top: 1.5rem;
    }

    .list-item {
      display: flex;
      padding: 0.5rem 0;
      font-size: var(--font-body-2);

      @include media-breakpoint-up('lg') {
        font-size: var(--font-body-1);
      }

      .label {
        width: 3.94rem;
        color: var(--Font-Light, #7a7a85);

        @include media-breakpoint-up('lg') {
          width: 4.5rem;
        }
      }

      .text {
        flex: 1;
      }

      img {
        margin-right: 0.5rem;
        margin-top: -2px;
      }
    }
  }

  .join-benefit-group {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    .list-item {
      display: inline-block;
      text-align: center;
      font-size: var(--font-body-2);

      img {
        margin-bottom: 1rem;
      }

      > * {
        display: block;
      }

      + .list-item {
        > * {
          padding-left: 1rem;
          margin-left: 1rem;
        }

        img {
          border-left: 1px solid var(--Border-DividerLight);
        }
      }
    }
  }
}
