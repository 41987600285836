// 나의 문의 + 리뷰

.qna-group-list {
  padding-top: 1.5rem;

  .qna-group-item {
    border-radius: var(--radius-product);
    border: 1px solid var(--Border-DividerLight);
    overflow: hidden;

    + .qna-group-item {
      margin-top: 0.75rem;
    }

    .qna-item {
      // 타이틀 영역
      .title-wrap {
        display: flex;
        padding: 1rem 3rem 1rem 1rem;

        @include background-img(1.5rem, calc(100% - 1rem) 1rem);
        background-image: url(/assets/ic/chevron-down-bk.svg);

        @include media-breakpoint-up('lg') {
          padding: 1.5rem 4.5rem 1.5rem 1.5rem;
          @include background-img(1.5rem, calc(100% - 1.5rem) 1.5rem);
        }

        .badge-payment {
          margin-right: 0.5rem;
        }

        .title {
          flex: 1;
          @include size(100%, auto);
          @include multi-truncate(1);
          font-size: var(--font-body-2);
          line-height: 1.5rem;
          padding-left: 1.25rem;
          margin-right: 0.5rem;

          @include background-img(1rem auto, 0 -1px);
          background-image: url(/assets/ic/q.svg);
        }

        // 자주하는 질문에서 나오는 title
        .full-text {
          flex: 1;
          @include size(100%, auto);
          font-size: var(--font-body-2);
          line-height: 1.5rem;
          margin-right: 0.5rem;
        }
      }

      // 질문 영역
      .question-wrap {
        display: none;
        padding: 0 1rem 1.5rem 1rem;

        > img {
          @include size(100%, auto);
          border-radius: var(--space-xxs);
          border: 1px solid rgba(0, 0, 0, 0.08);
          margin-top: 1.5rem;
          max-width: 23.4375rem;
        }

        .btn-inline-group {
          margin-top: 1.5rem;
          margin-bottom: 0.5rem;
        }

        .product-item {
          border-top: 1px solid var(--Border-DividerLight);
          padding-top: 1.5rem;
          margin-top: 1.5rem;
        }

        .thumbnail {
          @include size(5rem);
        }
      }

      // 답변 영역
      .answer-wrap {
        display: none;
        padding: 1.5rem;
        background: var(--Background-InfoSection);

        @include media-breakpoint-up('lg') {
          padding: 2.5rem;
        }

        .title {
          display: flex;
          font-weight: 700;
          margin-bottom: 1.5rem;

          .done {
            flex: 1;
            @include background-img(1rem auto, 0 -1px);
            background-image: url(/assets/ic/a.svg);
            padding-left: 1.25rem;
          }
        }

        .text {
          color: var(--Font-Middle);
        }
      }
    }

    // 활성화 되었을 때
    &.active {
      border: 1px solid var(--Border-DividerMiddle);

      .title-wrap {
        display: block;
        background-image: url(/assets/ic/chevron-up-bk.svg);

        @include media-breakpoint-up('lg') {
          display: flex;
        }

        .title {
          margin-top: 0.5rem;

          @include media-breakpoint-up('lg') {
            margin-top: 0;
          }
        }

        .date {
          padding-left: 1.25rem;
        }
      }

      .question-wrap,
      .answer-wrap {
        display: block;
      }
    }

    // 공통
    .date {
      font-weight: 400;
      font-size: var(--font-body-3);
      line-height: 1.5rem;
      color: var(--Font-Light);
    }
  }
}

.section-contents {
  &.noti-review {
    font-size: var(--font-body-2);
    color: var(--Font-Middle);

    span {
      color: var(--Theme-Secondary);
    }

    ul {
      list-style: disc;
      padding-top: 1rem;
      padding-left: 1rem;

      li {
        + li {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.myReview-list {
  .myReview-item {
    overflow: hidden;
    border-radius: var(--radius-product);
    border: 1px solid var(--Border-DividerLight);

    &.close {
      .myReview-wrap {
        grid-template-columns: 1fr 8rem 8rem 1.5rem;
      }
    }

    &.active {
      border: 1px solid var(--Border-DividerMiddle);

      .myReview-wrap {
        grid-template-columns: 1fr 8rem 8rem 1.5rem;
      }

      .product-item {
        padding-bottom: 0;
      }

      .myReview-result-wrap {
        display: block;
        grid-column: 1 / 5;
        grid-row: 2;
      }
    }

    + .myReview-item {
      margin-top: 0.75rem;
    }

    .myReview-wrap {
      position: relative;

      @include media-breakpoint-up('lg') {
        display: grid;
        grid-template-columns: 1fr 8rem 8rem;
        gap: 1rem;
        padding-right: 1.5rem;
      }
    }

    .review-data {
      padding: 1rem;

      @include media-breakpoint-up('lg') {
        padding: 0.5rem 0 1.5rem 1.5rem;
      }

      .stars {
        padding-top: 1rem;
        border-top: 1px solid var(--Border-DividerLight);
        margin-bottom: 1rem;

        img {
          @include size(1rem);
          margin-bottom: 0.15rem;
        }

        .point {
          font-weight: 700;
          font-size: var(--font-body-2);
          margin-left: 0.5rem;
        }
      }

      .main-text {
        img {
          display: block;
          @include size(7.5rem, auto);
          border-radius: 0.5rem;
          border: 1px solid rgba(0, 0, 0, 0.08);
          margin-top: 1rem;
        }
      }
    }

    .product-item {
      padding: 1rem;

      @include media-breakpoint-up('lg') {
        order: 1;
        padding: 1.5rem;
      }

      .title {
        @include multi-truncate(1);
      }
    }

    .review-info {
      padding: 1rem 1rem 0 1rem;

      @include media-breakpoint-up('lg') {
        order: 2;
        text-align: center;
        padding: 0 1rem;
        align-self: center;
      }

      .state {
        display: inline-block;
        font-weight: 700;

        @include media-breakpoint-up('lg') {
          display: block;
        }
      }

      .subtext {
        display: none;
        font-size: var(--font-body-3);
        color: var(--Font-Light, #7a7a85);

        @include media-breakpoint-up('lg') {
          display: block;
          margin-top: 0.25rem;
        }
      }
    }

    .btn-delete {
      position: absolute;
      top: 1rem;
      right: 1rem;

      @include media-breakpoint-up('lg') {
        position: relative;
        top: unset;
        right: unset;
        order: 4;
        padding-top: 1.5rem;
      }
    }

    .review-action {
      padding: 0rem 1rem 1rem 1rem;

      @include media-breakpoint-up('lg') {
        order: 3;
        text-align: center;
        padding: 0;
        align-self: center;
      }

      &.review-close,
      &.review-open {
        padding-top: 0.75rem;
        border-top: 1px solid var(--Border-DividerLight, #e4e4e7);

        .btn-subtle {
          height: 1.25rem;
        }
      }

      &.review-close {
        @include media-breakpoint-up('lg') {
          padding: 0.75rem 1rem 1rem 1rem;
        }
      }

      &.review-open {
        .btn-subtle {
          @include media-breakpoint-up('lg') {
            height: auto;
            line-height: 1.5rem;
            text-decoration: underline;
          }
        }

        @include media-breakpoint-up('lg') {
          border-top: none;
          padding-top: 0;
        }
      }

      .point {
        font-weight: 700;
        color: var(--Theme-Secondary);
      }
    }

    .myReview-result-wrap {
      display: none;
    }

    // 공통
    .date {
      display: inline-block;
      font-size: var(--font-body-3);
      color: var(--Font-Light, #7a7a85);
      margin-left: 0.5rem;
      vertical-align: text-top;

      @include media-breakpoint-up('lg') {
        display: block;
        margin-left: 0;
        margin-top: 0.25rem;
      }

      &.mobile-date {
        @include media-breakpoint-up('lg') {
          display: none;
        }
      }

      &.desktop-date {
        display: none;

        @include media-breakpoint-up('lg') {
          display: block;
        }
      }
    }
  }
}

// 1:1 문의하기 하단 버튼 대응
.btn-inline-group {
  &.one-on-one {
    display: block;

    @include media-breakpoint-up('lg') {
      display: none;
    }

    padding-bottom: 2rem;
  }
}
