// Button
button {
  &:disabled,
  &[disabled] {
    pointer-events: none;
    color: var(--Font-Disabled);
    background-color: var(--Background-Disabled);

    img {
      opacity: 0.3;
    }
  }
}

.btn {
  position: relative;
  vertical-align: middle;
  text-align: center;
  @include size(auto);
  display: inline-block;
  font-weight: 700;
  border-radius: var(--radius-xs);
  color: var(--Font-Heavyy);
  line-height: 1.5rem; /* 150% */

  img {
    @include size(1.5rem);
    margin-right: 0.25rem;
    margin-top: -0.25rem;
  }
}

//Sizing
.btn-sm {
  border-radius: var(--radius-xxs);
  font-weight: 400;
  @include size(auto, 2rem);
  padding: 0 0.75rem;
  font-size: var(--font-body-2);
  line-height: 1.25rem;
  letter-spacing: -0.00438rem;
}

.btn-md {
  @include size(auto, 3rem);
  font-weight: 400;
  padding: 0 1.5rem;
  font-size: var(--font-body-1);
}

.btn-lg {
  @include size(auto, 3.5rem);
  padding: 0 1rem;
  font-size: var(--font-heading-3);
}

.btn-block {
  display: block;
  width: 100%;
}

// Style
.btn-outline {
  background-color: var(--Static-White);
  border: 1px solid var(--Border-Button);

  &:hover {
    background-color: var(--Background-ButtonHovered);
  }

  &:active {
    background-color: var(--Background-ButtonPressed);
  }

  &:disabled,
  &[disabled] {
    border: none;
  }
}

.btn-tonal {
  border: 1px solid var(--Border-ButtonTonal);
  background: var(--Background-ButtonTonal);

  &:hover {
    border: 1px solid var(--Border-ButtonTonalHovered);
    background: var(--Background-ButtonTonalHovered);
  }

  &:active {
    border: 1px solid var(--Border-ButtonTonalPressed);
    background: var(--Background-ButtonTonalPressed);
  }

  &:disabled,
  &[disabled] {
    border: none;
  }
}

.btn-filled {
  color: var(--Static-White);
  background-color: var(--Theme-Primary);
  font-weight: 700;

  &:hover {
    background-color: var(--Background-ButtonPrimaryHovered);
  }

  &:active {
    background-color: var(--Background-ButtonPrimaryPressed);
  }
}

.btn-subtle {
  background-color: transparent;
  font-weight: 400;

  &:hover {
    background-color: var(--Background-ButtonHovered);
  }

  &:active {
    background-color: var(--Background-ButtonPressed);
  }

  &:disabled,
  &[disabled] {
    background-color: transparent;
  }
}

.btn-link {
  font-size: 1.125rem;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }

  &:disabled,
  &[disabled] {
    background-color: transparent;
  }
}

// Arrow Button
.btn-rounded {
  position: relative;
  @include size(2.5rem);

  border-radius: var(--radius-full);
  box-shadow: 0px 4px 8px 0px rgba(44, 53, 88, 0.08);

  @include background-img(1rem, center);
  background-image: url(/assets/ic/chevron-left.svg);

  &.right {
    background-image: url(/assets/ic/chevron-right.svg);
  }

  &.go-top {
    @include background-img(1.5rem, center);
    background-image: url(/assets/ic/go-top.svg);
  }
}

// 버튼 그룹 처럼 생겼지만 라디오버튼처럼 작동함
.btn-check-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  border-radius: var(--radius-xs);

  > * {
    + * {
      margin-left: -1px;
    }

    &:checked + .btn + .btn-check + .btn {
      border-left: 1px solid var(--Border-DividerHeavy);
    }
  }

  .btn {
    border-radius: 0;
    color: var(--Font-Light);
    background: var(--Background-Optional);
  }

  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:first-child + .btn {
      border-top-left-radius: var(--radius-xs);
      border-bottom-left-radius: var(--radius-xs);
    }

    &:checked + .btn {
      color: var(--Font-Heavyy);
      border: 1px solid var(--Border-DividerHeavy);
      background: var(--Static-White);
      font-weight: 700;
    }
  }

  .btn-md {
    line-height: 3rem;
  }

  .desktop-last-child {
    @include media-breakpoint-up('lg') {
      border-top-right-radius: var(--radius-xs);
      border-bottom-right-radius: var(--radius-xs);
    }
  }

  .desktop-disabled {
    border-top-right-radius: var(--radius-xs);
    border-bottom-right-radius: var(--radius-xs);

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }
}

.btn-icon-only {
  text-align: center;
  @include size(3.5rem);
  padding: 1rem;
}

// md icon-only
.btn-md-icon-only {
  text-align: center;
  @include size(3rem);
  padding: 0.75rem;
}

// Button group
.btn-inline-group {
  display: flex;

  * + * {
    margin-left: 0.5rem;
  }

  // 모바일에서는 버튼의 너비가 100%, 데스크탑부터 auto
  &.mobile-fluid {
    > * {
      width: 100%;

      @include media-breakpoint-up('lg') {
        width: auto;
        text-align: center;
      }
    }
  }
}

// dropdown
.dropdown {
  position: relative;
}

.dropdown-toggle {
  position: relative;
  display: block;
  @include size(100%, 3rem);
  padding-left: 1rem;
  padding-right: calc(1.5rem + 1rem + 2rem); // 시각보정 버퍼 2rem
  font-weight: 400;
  text-align: left;
  font-size: var(--font-body-2);
  border: 1px solid var(--Border-Button);
  @include background-img(1.5rem, right calc(1rem) center);
  background-image: url(/assets/ic/chevron-down-bk.svg);

  @include media-breakpoint-up('xlg') {
    font-size: var(--font-body-1);
  }

  sup {
    color: var(--Theme-Secondary);
  }

  /* // hover에 문제를 일으키는 구간
  &:hover {
    background-color: var(--Static-White);
  }
  */

  &:disabled,
  &[disabled] {
    border: 1px solid var(--Border-Button);
    background-image: url(/assets/ic/chevron-down-gr.svg);
  }

  &.menu-open {
    background-image: url(/assets/ic/chevron-up-bk.svg);
    border-radius: var(--radius-xs) var(--radius-xs) 0 0;
  }
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: var(--Static-White);
  border: 1px solid var(--Border-Button);
  border-radius: 0 0 var(--radius-xs) var(--radius-xs);
  @include size(100%, auto);
  padding: 0;
  z-index: var(
    --z-index-dropdown
  ); // NOTI: 네비게이션보다 Z-index 값이 낮아야 함
  top: calc(3rem - 1px);
  max-height: 20rem;
  overflow-y: scroll;

  &.show {
    display: block;
  }

  // 리스트 10개 이상
  &.scroll {
    padding: 0.5rem 1rem 0.5rem 0;
    height: 16rem;
    overflow: scroll;
  }

  .dropdown-item {
    padding: 0.75rem 1rem;
    @include size(100%, auto);
    color: var(--Font-Heavyy);
    font-size: var(--font-body-2);
    display: flex;

    @include media-breakpoint-up('xlg') {
      font-size: var(--font-body-1);

      &:hover {
        background-color: var(--Background-DropdownLayer);
      }
    }

    > * + * {
      margin-left: 0.75rem;
    }

    > .option {
      color: var(--Font-Light);
    }

    .title {
      width: 100%;
      flex: 1;
    }

    .price {
      text-align: right;
    }

    .alert {
      color: var(--Theme-Secondary);
    }

    &.active {
      background-color: var(--Background-DropdownLayer);
    }

    // soldout & disabled
    &.soldout {
      color: var(--Font-Disabled);

      .option {
        color: var(--Font-Disabled);
      }

      .discount-percent {
        color: var(--Font-Disabled);
      }
    }

    .discount-percent {
      font-weight: 400;
      margin-right: 0.5rem;
    }
  }
}

// Badges

.badge {
  display: inline-block;
  @include size(auto, 1.25rem);
  padding: 0 0.38rem;

  line-height: 1.25rem;
  font-size: 0.6875rem;
  letter-spacing: -0.00688rem;

  background-color: var(--Background-Tag);
  color: var(--gray-500);
  border-radius: var(--radius-xxs);

  @include media-breakpoint-up('xlg') {
    @include size(auto, 1.56rem);
    padding: 0 0.5rem;
    line-height: 1.56rem;

    font-size: 0.75rem;
    letter-spacing: -0.0075rem;
  }

  &.red {
    color: var(--Brand-Pink-600);
    border: 1px solid var(--Brand-Pink-600);
    background-color: #fff0f3;
  }
}

.badge-amount {
  position: absolute;
  top: -4px;
  right: -8px;
  @include size(auto, 1.25rem);
  min-width: 1.25rem;

  padding: 0 0.38rem;
  background-color: var(--Theme-Secondary);
  border-radius: var(--radius-full);

  font-size: 0.6875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: var(--Static-White);
  text-align: center;
}

.badge-payment {
  display: inline-block;
  word-break: keep-all;
  @include size(auto, 1.5rem);

  padding: 0.25rem 0.5rem;
  border-radius: var(--radius-full);
  background: var(--Theme-Primary);
  color: var(--Static-White);
  font-size: var(--font-body-3);
  font-weight: 700;

  @include media-breakpoint-up('lg') {
    padding: 0.25rem 0.75rem;
  }

  &.light {
    background-color: var(--Font-Disabled);
  }

  &.dark {
    background: var(--Font-Middle);
  }
}

// ios 크롬에서 메일에 자동으로 밑줄이 쳐지며 링크가 클릭되는 현상 제거
a.no-link[href^='tel'],
a.no-link[href^='mailto'],
.no-link {
  color: inherit;
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}
