// 기획전

.special-event-wrap {
  @include size(100%, auto);
  padding-left: 0;
  padding-right: 0;

  .jumbotron {
    padding: 1.5rem;
    font-size: var(--font-heading-3);
    font-weight: 700;

    @include media-breakpoint-up('md') {
      padding: 2rem;
      font-size: var(--font-heading-1);
    }
  }

  .default-img {
    display: block;
    @include size(100%, auto);
  }

  .link-img {
    img {
      display: block;
      @include size(100%, auto);
    }
  }

  .product-wrap {
    padding-left: 1rem; // 16px gutter
    padding-right: 1rem; // 16px gutter

    @include media-breakpoint-up('md') {
      padding-left: 1.5rem; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    @include media-breakpoint-up('lg') {
      padding-left: 1.5rem; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    @include media-breakpoint-up('xlg') {
      padding-left: 0;
      padding-right: 0;

      max-width: 1128px; // flexible + 36px gutter
      margin-left: auto;
      margin-right: auto;
    }
  }
}
