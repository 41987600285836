// 나의 정보 수정

.myInfo-forms-group {
  .myInfo-forms-item {
    padding: 1rem 0;
    border-bottom: 1px solid var(--Border-DividerLight, #e4e4e7);

    @include media-breakpoint-up('lg') {
      display: flex;
      padding: 0 1rem;
    }

    .label {
      padding: 0.5rem 0;
      color: var(--Font-Light, #7a7a85);
      font-size: var(--font-body-2);

      @include media-breakpoint-up('lg') {
        @include size(6rem, 5rem);
        line-height: 5rem;
        padding: 0;
        font-size: var(--font-body-1);
      }
    }

    .controls,
    .input-group,
    .forms-group {
      @include media-breakpoint-up('lg') {
        min-height: 5rem;
        line-height: 3rem;
        flex: 1;
        padding: 1rem 0;
      }
    }

    .forms-group {
      .input-group {
        padding: 0;
        flex: 1;
        min-height: unset;
        line-height: inherit;

        + * {
          margin-left: 0.5rem;
        }
      }
    }

    .input-group {
      &.inline {
        > button {
          width: auto;
        }
      }

      &.nickname {
        .text {
          flex: 1;
          line-height: 2rem;

          .hint {
            color: var(--Font-Disabled);
            font-size: var(--font-body-2);
          }

          @include media-breakpoint-up('lg') {
            line-height: 3rem;
          }
        }
      }

      .avatar {
        vertical-align: middle;
        display: inline-block;
        @include size(2rem);
        margin-right: 0.5rem;
      }

      .btn-sm {
        @include media-breakpoint-up('lg') {
          @include size(auto, 3rem);
          padding: 0 1.5rem;
          font-size: var(--font-body-1);
          border-radius: var(--radius-xs);
        }
      }

      > img {
        display: inline-block;
        margin-top: -4px;

        + .text {
          margin-left: 0.5rem;
          display: inline-block;
        }
      }
    }

    .form-text {
      line-height: 1rem;
    }
  }
}

.myInfo-terms {
  @include media-breakpoint-up('lg') {
    border-radius: var(--radius-product);
    border: 1px solid var(--Border-DividerLight);
  }

  &.active {
    @include media-breakpoint-up('lg') {
      border: 1px solid var(--Border-DividerMiddle);
    }

    .title-wrap {
      background-image: url(/assets/ic/chevron-up-bk.svg);
    }

    .terms-wrap {
      display: block;
    }
  }

  .title-wrap {
    font-weight: 700;
    padding: 1.5rem 0;

    @include background-img(1.5rem, calc(100%) center);
    background-image: url(/assets/ic/chevron-down-bk.svg);

    @include media-breakpoint-up('lg') {
      padding: 1.5rem;
      @include background-img(1.5rem, calc(100% - 1.5rem) center);
    }

    > img {
      margin-left: 0.75rem;
      margin-top: -2px;
    }
  }

  .terms-wrap {
    display: none;

    @include media-breakpoint-up('lg') {
      padding: 0.5rem 1.5rem 2.5rem 1.5rem;
    }

    color: var(--Font-Middle);

    .outer-list {
      > .list-item {
        margin-bottom: 1rem;
      }
    }

    .inner-list {
      margin-top: 0.38rem;
      padding-left: 1.6rem;

      .list-item {
        display: inline-block;

        + .list-item {
          margin-left: 1.25rem;
        }
      }

      .info {
        font-size: var(--font-body-3);
        color: var(--Font-Light);
      }
    }
  }
}

.section-contents {
  &.withdrawal {
    padding-top: 1.5rem;
  }
}

// 데스크탑에서만 나오는 중앙정렬 버튼 그룹
.centered-desktop-action {
  display: none;
  text-align: center;

  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  margin-top: 2.5rem;

  @include media-breakpoint-up('lg') {
    display: block;
  }

  .btn {
    + .btn {
      margin-left: 1rem;
    }
  }
}
