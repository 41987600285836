// Forms

// Input groups
input {
  &:disabled,
  &[disabled] {
    pointer-events: none;
    background-color: var(--Background-Disabled);
    color: var(--Font-Disabled);
  }
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  &.form-control {
    appearance: none; // Fix appearance for date inputs in Safari
  }
}

.form-control {
  @include size(100%, 3rem); // 48px

  border: 1px solid var(--Border-Button);
  border-radius: var(--radius-xs);
  color: var(--Font-Heavy);
  padding: 1rem 0.75rem;
  font-size: var(--font-body-2);

  &:hover {
    border: 1px solid var(--Theme-Teal-900);
  }

  &:focus {
    border: 1px solid var(--Theme-Teal-900);
  }

  &:active {
    border: 1px solid var(--Font-Heavy);
  }

  &.with-icon {
    @include background-img(24px 24px, 12px 14px);
    padding-left: 44px;
  }

  &.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    @include background-img(1.5rem, right calc(0.375em + 0.1875rem) center);
    background-image: url('/assets/ic/checked-green.svg');
  }

  &.has-validation {
    border: 1px solid var(--Status-error);

    + .form-text {
      color: var(--Status-error);
      @include background-img(1rem, 0 -0.25px);
      background-image: url(/assets/ic/info-red.svg);
      padding-left: 1.25rem;
    }
  }
}

.forms-group {
  display: flex;

  > .form-control {
    flex: 1;
    margin-right: 0.5rem;
  }

  + * {
    margin-top: 0.5rem;
  }
}

// 검색
.forms-search {
  position: relative;

  .search {
    padding-right: calc(1.5em + 0.75rem);
  }

  .search-submit {
    position: absolute;
    right: calc(0.375em + 0.5rem);
    top: 0.75rem;
    cursor: pointer;
  }
}

// 캘린더
.forms-calendar {
  display: flex;
  position: relative;
  @include size(auto, 3rem);
  line-height: 3rem;
  border: 1px solid var(--Border-Button);
  border-radius: var(--radius-xs);
  overflow: hidden;

  background-color: var(--Static-White);

  input {
    padding: 0.75rem 2rem 0.75rem 1rem;
    @include size(100%);
    border: none;
    background-color: transparent;
    color: var(--Font-Light);

    @include background-img(1.5rem, right calc(0.375em + 0.1875rem) center);
    background-image: url('/assets/ic/calendar.svg');

    &::-webkit-calendar-picker-indicator {
      // 마이-주문배송 캘린더 이슈 보완
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0;
      border: none;
    }
  }
}

.form-label {
  font-weight: 700;
  margin-bottom: 0.75rem;

  sup {
    color: var(--Theme-Secondary);
    margin-left: 0.25rem;
  }

  .link {
    float: right;
    color: var(--Font-Middle);
    font-weight: 400;
    font-size: var(--font-body-2);
  }
}

.form-text {
  display: block;
  color: var(--Font-Light);
  font-size: var(--font-body-3);
  margin-top: 0.5rem;
  padding-left: 0.25rem;

  .password-check {
    display: inline-block;
    @include background-img(1rem, 0 center);
    background-image: url(/assets/ic/check-gr-sm.svg);
    padding-left: 1.12rem;

    + .password-check {
      margin-left: 0.5rem;
    }

    &.is-valid {
      color: var(--Font-Success, #22c55e);
      background-image: url(/assets/ic/check-green-sm.svg);
    }
  }
}

// Form 구성요소
.form-title-wrap {
  padding: 1.5rem 0;

  @include media-breakpoint-up('lg') {
    padding: 2.5rem 0;
  }
}

.form-title {
  font-size: var(--font-heading-3);

  + .form-sub-text {
    margin-top: 1rem;
  }
}

.form-sub-text {
  font-size: var(--font-body-2);
  color: var(--Font-Light);

  .info-list-group {
    list-style-type: disc;
    padding-left: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--Border-DividerLight, #e4e4e7);
    margin-bottom: 1rem;

    .list-item {
      span {
        color: var(--Theme-Secondary);
      }

      + .list-item {
        margin-top: 0.5rem;
      }
    }
  }
}

textarea {
  resize: none;
  vertical-align: bottom;

  &.form-control {
    @include size(100%, 12.5rem);
    padding: 0.75rem 1rem 1.75rem 1rem;
  }
}

// Text area value
.view-words {
  position: absolute;
  display: block;
  right: 1rem;
  bottom: 1px;
  color: var(--Font-Light);
  font-size: var(--font-body-3);
  background-color: var(--Static-White);
  @include size(calc(100% - 2rem), 1.75rem);
  text-align: right;

  .value {
    color: var(--Font-Heavy);
  }
}

// Input group
.input-group {
  position: relative;
  @include size(100%, auto);

  &.inline {
    display: flex;

    > * {
      width: 100%;
    }

    > * + * {
      margin-left: 0.5rem;
    }
  }

  &.address-search {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--Border-DividerLight);
    .form-control {
      + .form-control {
        margin-top: 0.5rem;
      }
    }

    .checkbox-group {
      margin-top: 0.75rem;
    }
  }

  + .input-group {
    margin-top: 1.5rem;
  }

  .form-control {
    & + .checkbox-group {
      margin-top: 0.75rem;
    }
  }
}

// Checkbox group
.checkbox-group {
  position: relative;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5rem 0 0.5rem 2rem;
  color: var(--Font-Middle);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        @include background-img(1.5rem, 0 center);
        background-image: url(/assets/ic/checked-bk.svg);
      }
    }
  }

  .checkmark {
    position: absolute;
    top: auto;
    left: 0;
    @include size(1.5rem);

    @include background-img(1.5rem, 0 center);
    background-image: url(/assets/ic/checked-light-gr.svg);
  }

  &.green-box {
    input {
      &:checked {
        ~ .checkmark {
          @include background-img(1.5rem, 0 center);
          background-image: url(/assets/ic/checkbox-green.svg);
        }
      }
    }

    .checkmark {
      background-image: url(/assets/ic/checkbox-gr.svg);
    }
  }

  &.sm-box {
    font-size: var(--font-body-2);
    font-weight: 400;
    padding: 0 0 0 1.5rem;

    input {
      &:checked {
        ~ .checkmark {
          @include background-img(1.25rem, 0 center);
        }
      }
    }

    .checkmark {
      top: 2px;
      @include size(1.25rem);
      background-size: 1.25rem;
    }

    .label {
      line-height: 1.5rem;
    }
  }
}

// Radio group
.radio-group {
  position: relative;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5rem 0 0.5rem 2rem;

  .label {
    color: var(--Font-Light);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        @include background-img(1.5rem, 0 center);
        background-image: url(/assets/ic/radio-on.svg);
      }

      + .label {
        color: var(--Font-Heavy);
      }
    }
  }

  .checkmark {
    position: absolute;
    top: auto;
    left: 0;
    @include size(1.5rem);

    @include background-img(1.5rem, 0 center);
    background-image: url(/assets/ic/radio-off.svg);
  }

  &.green-active-wrap {
    padding: 0;

    .label {
      padding: 0.75rem 1rem;
      border: 1px solid var(--Border-DividerLight);
      border-radius: var(--radius-xs);
      color: var(--Font-Heavy);

      .title {
        display: flex;
        padding-left: 2rem;
      }

      .info {
        display: inline-block;
        font-size: var(--font-body-3);
        margin-left: 0.5rem;
        color: var(--Font-Light);
        margin-top: 3px;
      }
    }

    .checkmark {
      top: 0.8rem;
      left: 1rem;
    }

    input {
      &:checked {
        + .label {
          border: 1px solid var(--Theme-Primary);
          background: var(--Background-SelectInfo);
        }
      }
    }
  }

  &.address {
    .label {
      padding: 1rem 1.5rem;
    }

    .checkmark {
      top: 1.3rem;
      left: 1.5rem;
    }

    .title {
      display: flex;

      .name {
        flex: 1;
        line-height: 2rem;
        font-weight: 700;

        .option {
          margin-left: 0.25rem;
          font-weight: 400;
        }
      }

      .btn {
        + .btn {
          margin-left: 0.5rem;
        }
      }
    }

    .info {
      font-size: var(--font-body-2);
      color: var(--Font-Middle);
    }

    .address {
      padding-top: 0.75rem;
      padding-bottom: 0.5rem;
    }
  }
}

// Stepper
.stepper {
  position: relative;
  @include size(7rem, 2.5rem);

  &.disabled {
    input {
      pointer-events: none;
      background-color: var(--Background-Disabled);
      color: var(--Font-Disabled);
    }
  }

  button {
    &:disabled {
      background-color: transparent;
    }
  }

  .form-control {
    padding-left: calc(0.5rem + 1.5rem + 0.5rem);
    padding-right: calc(0.5rem + 1.5rem + 0.5rem);
    @include size(100%);
    text-align: center;
    font-weight: 700;
  }

  .value-control {
    position: absolute;
    @include size(1.5rem);
    top: 0.5rem;
    @include background-img(1.5rem 1.5rem, 100% 0);

    &.decrease {
      left: 0.5rem;
      background-image: url(/assets/ic/stepper-minus-bk.svg);

      &.disabled {
        background-image: url(/assets/ic/stepper-minus-gr.svg);
      }
    }

    &.increase {
      right: 0.5rem;
      background-image: url(/assets/ic/stepper-plus-bk.svg);

      &.disabled {
        background-image: url(/assets/ic/stepper-plus-gr.svg);
      }
    }
  }
}

// Callout
.callout {
  font-size: var(--font-body-2);
  border-radius: var(--radius-xs);
  border: 1px solid var(--Theme-Primary);
  background: var(--Theme-Teal-50);
  padding: 0.5rem;
  text-align: center;

  @include media-breakpoint-up('xlg') {
    font-size: var(--font-body-1);
    padding: 1rem 1.5rem;
  }
}

// Avatar
.avatar {
  position: relative;
  @include size(2.5rem);
  background-color: #d9d9d9;
  @include background-img(100%, center);
  background-image: url(/assets/ic/avatar-default.svg);
  overflow: hidden;
  border-radius: var(--radius-full);

  > img {
    position: absolute;
    @include size(100%);
  }
}

// Sort List
.sort-list {
  @include size(100%, auto);

  // 배송지 선택
  &.address-list {
    > * + * {
      margin-top: 0.5rem;
    }

    .btn-item {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  // 배송 요청사항
  &.desktop-half-list {
    @include media-breakpoint-up('lg') {
      display: flex;
      flex-wrap: wrap;
    }

    > * {
      margin-bottom: 0.5rem;

      @include media-breakpoint-up('lg') {
        width: calc(50% - 0.25rem);
        flex: 0 0 auto;

        margin-right: 0.5rem;
        margin-bottom: 0.5rem;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }

  // 주문 취소
  &.half-list {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: calc(50% - 0.25rem);
      flex: 0 0 auto;

      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
}

// Empty list
.empty-list {
  @include size(100%, auto);
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .sub-text {
    margin-top: 1.5rem;
    color: var(--Font-Light);
  }

  .point {
    color: var(--Theme-Secondary);
  }
}

// Lines
.lines {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  font-weight: 700;

  .line {
    @include size(100%, 0.75rem);
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #28c7ad52;
  }
}

// Terms List
.terms-list-group {
  margin-top: 2.5rem;
  color: var(--Font-Middle);

  > .list-item {
    margin-bottom: 2.5rem;

    &:last-child {
      padding-bottom: 2.5rem;
    }
  }

  .number-list-group {
    list-style: decimal;
    padding-left: 1.2rem;
    margin-top: 1rem;

    > .list-item {
      + .list-item {
        margin-top: 0.5rem;
      }
    }

    &.sm-list {
      list-style: none;
      padding-left: 0;

      .sub-title {
        margin-bottom: 0.5rem;
      }
    }
  }

  .dot-list-group {
    margin-top: 1rem;
    list-style: disc;
    padding-left: 1.2rem;

    > .list-item {
      font-size: var(--font-body-2);

      + .list-item {
        margin-top: 0.5rem;
      }
    }
  }

  .title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: var(--font-heading-3);
    color: var(--Font-Heavy);

    @include media-breakpoint-up('lg') {
      font-size: var(--font-heading-2);
    }
  }

  .link {
    text-decoration: underline;
    color: var(--Theme-Primary, #28c7ad);
  }

  .terms-table {
    @include size(100%, auto);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: var(--font-body-2);
    table-layout: fixed;
    overflow-wrap: break-word;

    th,
    td {
      border: 1px solid var(--Border-DividerLight);
      padding: 1rem;
      width: 100%;
    }

    th {
      text-align: center;
      font-weight: 400;
      background: var(--Background-InfoSection, #f2f2f3);
    }
  }

  .terms-box {
    border: 1px solid var(--Border-DividerLight, #e4e4e7);
    padding: 1.5rem;
    font-size: var(--font-body-2);
    margin-top: 1rem;
    margin-bottom: 1rem;

    .title {
      font-size: var(--font-body-2);
    }
  }
}

// 1:1 문의 스타일
.qa-form-contents {
  .form-title {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include media-breakpoint-up('lg') {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }

  .dropdown {
    padding-bottom: 1.5rem;
  }

  .with-icon {
    display: block;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    padding-left: calc(1.125rem + 0.25rem);

    font-size: var(--font-body-2);
    color: var(--Font-Light);

    @include background-img(1.125rem, 0 0);
    background-image: url(/assets/ic/info-gr.svg);

    a {
      text-decoration: underline;
    }
  }

  .img-uploaded {
    position: relative;
    @include size(7.5rem);
    border-radius: var(--radius-xs);
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;

    > img {
      @include size(7.5rem);
      object-fit: cover;
    }
  }

  .btn-delete {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    display: block;
    @include size(1.5rem);

    > img {
      @include size(1.5rem);
    }
  }
}
