// 주문/결제/배송내역

// 주문/결제/배송내역 에서 사용하는 컨텐츠 박스
.section-contents {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;

  //border-bottom: 1px solid var(--Border-DividerLight);

  @include media-breakpoint-up('lg') {
    padding-top: 0;
    padding-bottom: 2.5rem;

    border-bottom: 0;
  }

  + * {
    border-top: 0.5rem solid var(--gray-50);

    @include media-breakpoint-up('lg') {
      border-top: 0;
    }
  }

  &.border-top-0 {
    border-top: 0;
  }

  // 주문/결제/배송내역 에서 사용하는 컨텐츠 박스 중 캘린더박스
  &.tabs-calendar {
    padding: 1rem;

    @include media-breakpoint-up('md') {
      padding: 1rem 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      padding: 1rem 0;
    }
  }

  // 주문 상세 상단
  &.payment-complete-header {
    padding: 1rem;

    @include media-breakpoint-up('md') {
      padding: 1rem 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      padding: 1rem 1.5rem 1rem 0;
    }

    @include media-breakpoint-up('xlg') {
      padding: 1rem 0;
    }

    .section-title {
      height: auto;
      padding-top: 0;
      padding-bottom: 0;

      @include media-breakpoint-up('lg') {
        display: flex;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
      }

      > * {
        display: block;
      }

      .desktop-title {
        display: none;

        @include media-breakpoint-up('lg') {
          display: block;
        }
      }

      .prd-info {
        flex: 1;
        font-size: var(--font-body-1);

        @include media-breakpoint-up('lg') {
          padding-top: 6px;
          margin-left: 0.75rem;
          font-weight: 400;
        }
      }

      .prd-number {
        font-size: var(--font-body-2);
        font-weight: 400;
        color: var(--Font-Light);

        @include media-breakpoint-up('lg') {
          padding-top: 8px;
        }
      }

      + .checkbox-group {
        margin-top: 1rem;

        @include media-breakpoint-up('lg') {
          margin-top: 1.5rem;
        }
      }
    }
  }
}

// 쿠폰사용 & 포인트 묶음처럼 컨텐츠 박스 묶음
.section-contents-wrap {
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up('lg') {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  > .section-contents {
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up('md') {
      padding-left: 1.5rem; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    &:first-child {
      @include media-breakpoint-up('lg') {
        padding-left: 1.5rem; // 24px gutter
        padding-right: 0.75rem; // 12px grid gap
      }

      @include media-breakpoint-up('xlg') {
        padding-left: 0;
        padding-right: 0.75rem; // 12px grid gap
      }
    }

    &:last-child {
      @include media-breakpoint-up('lg') {
        padding-left: 0.75rem; // 12px grid gap
        padding-right: 1.5rem; // 24px gutter
      }

      @include media-breakpoint-up('xlg') {
        padding-left: 0.75rem; // 12px grid gap
        padding-right: 0;
      }
    }

    @include media-breakpoint-up('lg') {
      //padding-left: 1.5rem; // 24px gutter
      //padding-right: 0;
    }

    @include media-breakpoint-up('xlg') {
      //padding-left: 0;
      //padding-right: 0;

      //max-width: 1128px; // flexible + 36px gutter
      //margin-left: auto;
      //margin-right: auto;
    }
  }

  + * {
    border-top: 0.5rem solid var(--gray-50);

    @include media-breakpoint-up('lg') {
      border-top: 0;
    }
  }
}

// 배송조회
.selected-address {
  font-size: var(--font-body-2);

  @include media-breakpoint-up('lg') {
    font-size: var(--font-body-1);
    padding: 0 1rem;
  }

  &.shipping-info {
    margin-top: 1rem;

    @include media-breakpoint-up('lg') {
      padding-bottom: 1rem;
    }

    .row {
      margin-top: 1rem;
    }

    @include media-breakpoint-up('lg') {
      border-top: 1px solid var(--Border-DividerLight);
      border-bottom: 1px solid var(--Border-DividerLight);
    }
  }

  &.payment-done {
    border: 1px solid var(--Border-DividerMiddle);
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 0 1rem;

    + .payment-done {
      border-top: 1px solid var(--Border-DividerLight);
      border-bottom: 1px solid var(--Border-DividerMiddle);
    }
  }

  .border-wrap {
    padding: 1rem;
    border-radius: var(--radius-xs);
    border: 1px solid var(--Border-DividerLight);
    margin-bottom: 1rem;
    min-height: 4.125rem;

    @include media-breakpoint-up('lg') {
      padding: 0;
      border: none;
    }

    // 주문/결제 > 결제 완료 스타일 추가(10/31)
    &.payment-done {
      border: none;
      border-radius: 0;
      padding: 1rem 0 1rem 0;
      margin-bottom: 0;

      @include media-breakpoint-up('lg') {
        padding: 1.5rem 0 1.5rem 0;
      }

      .label {
        display: block;
      }

      .row {
        &.address {
          margin-bottom: 0;
        }
      }
    }

    .label {
      display: none;

      @include media-breakpoint-up('lg') {
        display: block;
      }
    }

    .text {
      color: var(--Font-Middle);
    }

    .row {
      &.address {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;

        @include media-breakpoint-up('lg') {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      // 주문/결제 > 결제 완료 스타일 추가(10/31)
      &.payment-done {
        font-weight: 700;

        @include media-breakpoint-up('lg') {
        }

        .label {
          color: var(--Font-Heavy);
          line-height: 1.875rem;
          font-size: var(--font-body-1);

          @include media-breakpoint-up('lg') {
            line-height: 2.25rem;
            font-size: var(--font-heading-2);
          }
        }

        .text {
          text-align: right;
          font-size: var(--font-heading-2);

          @include media-breakpoint-up('lg') {
            font-size: var(--font-heading-1);
          }

          .unit {
            font-weight: 400;
          }
        }
      }

      // 배송지 정보가 없을 때 스타일 추가(25.01.22)
      &.none {
        .text {
          margin-top: 0.375rem;
        }
      }
    }
  }

  .btn {
    display: block;
    position: absolute;
    right: 0;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  .row {
    position: relative;
    display: flex;

    .text {
      flex: 1;

      &.name {
        flex: unset;
        font-weight: 700;
        line-height: 2rem;

        color: var(--Font-Heavy);
        font-size: var(--font-body-1);

        @include media-breakpoint-up('lg') {
          font-weight: 400;
          line-height: inherit;
        }
      }
    }

    .badge {
      margin-top: 5px;
      margin-left: 0.5rem;

      @include media-breakpoint-up('lg') {
        margin-top: 1px;
      }
    }

    .label {
      color: var(--Font-Light);
      margin-right: 1rem;
      min-width: 5rem;

      @include media-breakpoint-up('lg') {
        min-width: 7.5rem;
      }
    }
  }
}

// 주문상품/My>주문/배송내역
.selected-payment-list {
  .payment-item {
    // 터치영역 넓히기 (250214)
    cursor: pointer;

    .border-wrap {
      border-radius: var(--radius-xs);
      border: 1px solid var(--Border-DividerLight);

      @include media-breakpoint-up('lg') {
        border: 1px solid var(--Border-DividerMiddle);
      }

      .title-col {
        display: flex;
        padding: 1rem;

        @include media-breakpoint-up('lg') {
          padding: 1.5rem 1.5rem 0 1.5rem;
        }

        &.mobile-border-bottom {
          border-bottom: 1px solid var(--Border-DividerLight);

          @include media-breakpoint-up('lg') {
            border: 0;
          }
        }

        &.pd-b-0 {
          padding-bottom: 0;
        }

        .title {
          font-weight: 700;
          flex: 1;

          @include media-breakpoint-up('lg') {
            font-size: var(--font-heading-3);
          }
        }

        .shipping-info {
          font-size: var(--font-body-2);
          color: var(--Font-Middle);
        }

        .link-more {
          font-size: var(--font-body-2);
          color: var(--Font-Middle);
        }
      }
    }

    + .payment-item {
      margin-top: 0.7rem;
    }
  }

  .selected-store-list {
    padding: 0 1rem;

    @include media-breakpoint-up('lg') {
      padding: 0 1.5rem;
    }

    .store-item {
      padding: 1rem 0;
      border-bottom: 1px solid var(--Border-DividerLight);

      &:last-child {
        border-bottom: 0;
      }

      // 주문/배송 내역 > 상세보기 > 주문 상품 리스트 박스
      &.payCom-store-item {
        @include media-breakpoint-up('lg') {
          display: flex;
        }

        &:first-child {
          padding-top: 0;

          @include media-breakpoint-up('lg') {
            padding-top: 1rem;
          }
        }

        &.disabled {
          .title {
            color: var(--Font-Disabled);
          }

          .option {
            color: var(--Font-Disabled);
          }

          .pricing {
            color: var(--Font-Disabled);
          }

          .amount {
            color: var(--Font-Disabled);
          }
        }

        .product-item {
          @include media-breakpoint-up('lg') {
            flex: 1;
          }
        }

        .checkbox-group {
          margin-right: 0.5rem;
        }

        @include media-breakpoint-up('lg') {
          padding-top: 1rem;
        }

        .btn-inline-group {
          margin-top: 1rem;

          @include media-breakpoint-up('lg') {
            margin-top: 0;
          }
        }

        // 상품별 배송 조회 버튼
        .tracking-status-controls {
          display: grid;
          grid-template-columns: repeat(2, 1fr); // 기본 2열로 정렬
          gap: 0.5rem;

          @include media-breakpoint-up('lg') {
            // Desktop에서 버튼 정렬 변경(최대 너비 내 inline 정렬)
            display: block;
            max-width: 260px;
            grid-template-columns: none;
            gap: 0;
            text-align: right;
          }

          .btn {
            // Desktop에서 버튼 정렬 변경(최대 너비 내 inline 정렬)
            @include media-breakpoint-up('lg') {
              display: inline-block;
              margin-left: 0.5rem;
              margin-bottom: 0.5rem;
            }

            & + .btn {
              margin-left: 0;

              @include media-breakpoint-up('lg') {
                margin-left: 0.5rem;
              }
            }

            &:only-child {
              grid-column: 1 / 3;

              @include media-breakpoint-up('lg') {
                // desktop 에서 제거
                grid-column: unset;
              }
            }

            &:nth-of-type(odd):last-child {
              grid-column: 1 / 3;

              @include media-breakpoint-up('lg') {
                // desktop 에서 제거
                grid-column: unset;
              }
            }
          }
        }
      }

      @include media-breakpoint-up('lg') {
        padding: 1.5rem 0;
      }

      .product-item {
        .info-wrap {
          // 주문/배송에서만 나오는 스타일(가격과 수량이 데스크탑에서 오른쪽에 있음)
          &.desktop-inline {
            @include media-breakpoint-up('lg') {
              display: grid;
              grid-template-columns: 1fr 20rem;
            }

            .all-inline {
              display: flex;
              margin-top: 0.5rem;

              @include media-breakpoint-up('lg') {
                display: grid;
                grid-template-columns: 1fr 1fr;
              }

              .inner-col {
                text-align: center;

                @include media-breakpoint-up('lg') {
                  width: 100%;
                }

                &.amount-col {
                  @include media-breakpoint-up('lg') {
                    order: -1;
                  }
                }
              }
            }

            .label {
              display: none;

              @include media-breakpoint-up('lg') {
                display: block;
              }
            }

            .pricing {
              @include media-breakpoint-up('lg') {
                font-size: var(--font-heading-3);
                font-weight: 700;
              }

              .unit {
                @include media-breakpoint-up('lg') {
                  font-size: var(--font-heading-3);
                  font-weight: 400;
                }
              }
            }

            .amount {
              @include media-breakpoint-up('lg') {
                height: inherit;
                line-height: inherit;
                font-size: var(--font-heading-3);
                font-weight: 700;
                color: var(--Font-Heavy);

                padding-left: 0;
                margin-left: 0;

                margin-top: 0;

                border-left: none;
              }

              .unit {
                @include media-breakpoint-up('lg') {
                  font-weight: 400;
                }
              }
            }
          }

          .title {
            margin-top: 0;
            @include multi-truncate(1);
          }
        }

        .thumbnail {
          @include size(5rem);
        }

        // 데스크탑에서만 표시되는 결제/배송/기타 관련 뱃지
        .payCom-state {
          display: none;

          @include media-breakpoint-up('lg') {
            display: flex;
            margin-bottom: 0.75rem;
          }
        }
      }

      // 모바일에서만 표시되는 결제/배송/기타 관련 뱃지
      .payCom-state {
        display: flex;
        margin-bottom: 1rem;

        @include media-breakpoint-up('lg') {
          display: none;
        }

        .state-detail {
          flex: 1;
          font-size: var(--font-body-3);
          color: var(--Font-Middle);
          margin-left: 0.5rem;
          margin-top: 0.25rem;

          .alert {
            color: var(--Theme-Secondary);
            font-weight: 700;
          }

          > * + * {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .btn-inline-group {
    display: flex;
    padding-bottom: 0;
    font-size: 0;

    @include media-breakpoint-up('lg') {
      display: block;
    }

    + .btn-inline-group {
      @include media-breakpoint-up('lg') {
        margin-left: 0.5rem;
      }
    }
  }

  + .btn-inline-group {
    margin-top: 2.5rem;

    @include media-breakpoint-up('lg') {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// 결제정보 테이블
.payment-table {
  .row {
    display: flex;
    height: 2rem;

    line-height: 2rem;

    @include media-breakpoint-up('lg') {
      height: 2.25rem;
      line-height: 2.25rem;
      padding: 0 1rem;
    }

    .price {
      flex: 1;
      text-align: right;
      font-size: var(--font-heading-3);
      font-weight: 700;

      .unit {
        font-size: var(--font-body-1);
        font-weight: 400;
      }
    }

    &.sub-row {
      font-size: var(--font-body-2);
      color: var(--Font-Light);

      .price {
        font-size: var(--font-body-2);
      }
    }

    &.shipping-info {
      margin-top: 0.5rem;

      @include media-breakpoint-up('lg') {
        margin-top: 1rem;
      }
    }

    &.total {
      height: auto;
      padding: 1rem 0;
      border-top: 1px solid var(--Border-DividerLight);
      margin-top: 1rem;

      @include media-breakpoint-up('lg') {
        padding: 1.5rem 1rem;
        border-bottom: 1px solid var(--Border-DividerLight);
        margin-top: 1.5rem;
      }

      .label {
        font-weight: 700;

        @include media-breakpoint-up('lg') {
          font-size: var(--font-heading-2);
        }
      }

      .price {
        font-size: var(--font-heading-2);

        @include media-breakpoint-up('lg') {
          font-size: var(--font-heading-1);
        }

        .unit {
          font-size: var(--font-heading-3);

          @include media-breakpoint-up('lg') {
            font-size: var(--font-heading-2);
          }
        }
      }
    }
  }
}

// 모바일 결제수단 체크박스
.mobile-option-checkbox {
  margin-top: 1.5rem;

  @include media-breakpoint-up('lg') {
    display: none;
  }
}

// 정보 제공 동의
.payment-notice {
  display: flex;
  padding: 1.5rem 0;

  @include media-breakpoint-up('lg') {
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  > * {
    display: block;
  }

  .text {
    flex: 1;
    font-size: var(--font-body-2);
    color: var(--Font-Middle);

    @include media-breakpoint-up('lg') {
      flex: unset;
    }
  }

  .more {
    font-size: var(--font-body-3);
    color: var(--Font-Light);
    text-decoration: underline;
  }
}

// 결제관련 최종 버튼
.payment-btn-wrap {
  padding-bottom: 0.5rem;

  @include media-breakpoint-up('lg') {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
  }

  > .btn {
    @include media-breakpoint-up('lg') {
      max-width: 25rem;
    }
  }
}
