//Guide forms
// 디자인을 보여주는 페이지에서 사용되며 본파일과는 상관없는 스타일입니다.

.global-container {
  &.guide {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);

    .guide-title {
      size: (100%, auto);
      border-bottom: 1px solid #6c6f89;
      padding-bottom: var(--space-sm);
      margin-bottom: var(--space-sm);

      > p {
        font-size: var(--font-body-3);
      }
    }

    .example {
      font-size: 0;
      padding: var(--space-md);
      border: 1px solid #e6e7ed;
      margin-bottom: var(--space-lg);

      > * + * {
        margin-top: var(--space-xs);
      }

      > .label {
        size: (100%, auto);
        color: var(--Font-Light);

        span {
          font-weight: 400;
          color: var(--Font-Light);
          font-size: var(--font-body-3);
          margin-left: 0.5rem;
        }
      }

      .btn {
        + .btn {
          margin-left: var(--space-sm);

          &.btn-block {
            margin-left: 0;
          }
        }
      }

      &.example-dropdown {
        .dropdown {
          display: inline-block;

          + .dropdown {
            margin-left: var(--space-md);
          }
        }
      }

      &.menu-display {
        position: relative;
        vertical-align: top;
        margin-top: 2rem;

        .dropdown-menu {
          vertical-align: top;
          width: 20rem;
          display: inline-block;
          position: relative;

          + .dropdown-menu {
            margin-top: var(--space-md);
            margin-left: 0;

            @include media-breakpoint-up('md') {
              margin-left: 2rem;
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .wrap-example {
    text-align: center;
    padding: var(--space-sm);
    background-color: var(--Brand-Pink-50);
    margin-bottom: var(--space-lg);

    @include media-breakpoint-up('md') {
      background-color: var(--Brand-Pink-100);
    }

    @include media-breakpoint-up('lg') {
      background-color: var(--Brand-Pink-200);
    }

    @include media-breakpoint-up('xlg') {
      background-color: var(--Brand-Pink-300);
    }

    p {
      &::after {
        content: ' Small (Mobile) : 375-599';

        @include media-breakpoint-up('md') {
          content: ' Medium (Mobile l / Tablet p ) : 600-904';
        }

        @include media-breakpoint-up('lg') {
          content: ' Large ( Tablet L + laptop ) : 905-1199';
        }

        @include media-breakpoint-up('xlg') {
          content: ' XLarge (Desktop) : 1200-Wide';
        }
      }
    }
  }
}
