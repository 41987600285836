// 검색

.empty-list {
  &.search {
    border-bottom: 1px solid var(--Border-DividerLight);
  }
}

.search-badge-group {
  font-size: 0;

  .badge-search {
    display: inline-block;
    @include size(auto, 2.5rem);
    padding: 0.5rem 0.75rem 0.5rem 1rem;

    font-size: var(--font-body-2);
    font-weight: 400;

    border-radius: var(--radius-full);
    border: 1px solid var(--Border-DividerLight, #e4e4e7);
    background: var(--Static-White, #fff);
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;

    + .badge-search {
      //margin-left: 0.5rem;
    }

    .btn-delete {
      display: inline-block;
      margin-left: 0.25rem;
      margin-top: -4px;
    }
  }

  &.best {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up('lg') {
      margin-bottom: 2.5rem;
    }

    > .badge-search {
      border: 1px solid var(--Border-ButtonTonal, #28c7ad);
      background: var(--Background-ButtonTonal, #f2fcfb);

      .btn-delete {
        display: none;
      }
    }
  }
}

.mobile-search-bar {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.25rem;
  margin-bottom: 0.5rem;

  @include media-breakpoint-up('lg') {
    display: none;
  }

  .forms-search {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.navbar {
  &.search {
    .title-bar {
      border-bottom: none;
    }
  }
}

.recent-search-none {
  padding-bottom: 1.5rem;
  color: var(--Font-Light, #7a7a85);
  font-size: var(--font-body-2);
}

.search-typing-delete-all {
  text-align: right;
  border-top: 1px solid var(--Border-DividerLight);
  padding: 0.75rem 1.5rem;

  a,
  .action {
    display: inline-block;

    color: var(--Font-Light, #7a7a85);
    font-size: var(--font-body-3);
    font-weight: 400;

    + a,
    .action {
      margin-left: 1rem;
    }
  }
}

.search-typing-list-group {
  .list-item {
    display: flex;
    font-size: var(--font-body-2);
    padding: 0.5rem 1rem 0.5rem 1.5rem;

    > * {
      display: block;
    }

    .title {
      flex: 1;

      .true {
        font-weight: 700;
      }
    }
  }
}

.search-typing-wrap {
  padding: 0;

  .recent-search-none {
    padding: 0.5rem 1.5rem;
  }
}

.desktop-search-menu {
  position: absolute;
  @include size(100%, auto);
  border-radius: var(--radius-s);
  border: 1px solid var(--Theme-Primary, #28c7ad);
  background: var(--Static-White, #fff);
  text-align: left;
  padding-top: 0.5rem;
  z-index: var(--z-index-header-menu);

  /* ShadowLarge */
  box-shadow: 0px 8px 16px 0px rgba(44, 53, 88, 0.1);

  .menu-title {
    padding: 0.5rem 1.5rem;
    font-weight: 700;
    font-size: var(--font-body-2);
  }

  .recent-search-none {
    padding: 0.5rem 1.5rem 1rem 1.5rem;
  }
}

.mobile-search-menu {
  position: absolute;
  padding-top: 1rem;
  @include size(100%, calc(100vh - 3.25rem - 3.375rem));
  background-color: var(--Static-White);
  z-index: var(--z-index-header-menu);

  .recent-search-none {
    padding: 0.75rem 1.5rem;
  }
}
