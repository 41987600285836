// 장바구니
.global-container {
  &.with-cart-bottom-nav {
    padding-bottom: var(--sm-BottomCartNavigaion);

    @include media-breakpoint-up('xlg') {
      padding-bottom: var(--lg-BottomCartNavigaion);
    }
  }
}

// 배송지
.resent-address-wrap {
  @include size(100%, 3rem);
  display: flex;
  background-color: var(--Background-Optional);
  padding: 0 1rem;
  font-size: var(--font-body-2);
  line-height: 3rem;

  @include media-breakpoint-up('lg') {
    @include size(calc(100% - 3rem), 3.5rem);
    padding: 0 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    line-height: 3.5rem;
  }

  @include media-breakpoint-up('xlg') {
    margin-left: auto;
    margin-right: auto;
  }

  .label {
    font-weight: 700;

    img {
      @include size(1.5rem);
    }

    .text {
      display: none;

      @include media-breakpoint-up('lg') {
        display: inline-block;
      }
    }
  }

  .address {
    @include truncate;
    flex: 1;
    color: var(--Font-Middle);
    margin-left: 0.25rem;
  }

  .btn {
    margin-top: 0.5rem;

    @include media-breakpoint-up('lg') {
      margin-top: 0.75rem;
    }
  }
}

// 카트 전체 감싸는 테이블
.cart-list-wrap {
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up('lg') {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  @include media-breakpoint-up('xlg') {
    margin-left: auto;
    margin-right: auto;
  }

  // 전체선택 필터 영역
  .select-filter {
    display: flex;
    @include size(100%, 4rem);
    padding: 1rem;
    border-bottom: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('md') {
      padding: 1rem 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      @include size(100%, 4.5rem);
      padding: 1.5rem 0 1rem 0;
      border-bottom: none;
    }

    .checkbox-group {
      padding-top: 0.3rem;

      &.sm-box {
        padding-left: 1.75rem;

        .checkmark {
          top: 6px;
        }
      }
    }

    .btn-group {
      flex: 1;
      text-align: right;

      > * + * {
        margin-left: 0.5rem;
      }
    }
  }

  // 전체 리스트 테이블영역
  .cart-group-list {
    border-top: 0.5rem solid var(--gray-50);

    @include media-breakpoint-up('lg') {
      border-top: none;
    }

    // 제조사별 리스트 아이템 테이블
    .cart-group-item {
      border-bottom: 1px solid var(--Border-DividerLight);

      @include media-breakpoint-up('lg') {
        border-top: none;
        border-radius: var(--radius-product);
        overflow: hidden;

        border: 1px solid var(--Border-DividerMiddle);
      }

      + .cart-group-item {
        border-top: 0.5rem solid var(--gray-50);

        @include media-breakpoint-up('lg') {
          border-top: none;
          margin-top: 1.5rem;

          border-top: 1px solid var(--Border-DividerMiddle);
        }
      }

      &:last-child {
        border-bottom: none;

        @include media-breakpoint-up('lg') {
          border-bottom: 1px solid var(--Border-DividerMiddle);
        }
      }
    }
  }
}

// 개별 제품 관련
.cart-item {
  // 제조사별 선택 필터
  .store {
    .label {
      font-size: var(--font-heading-3);
      font-weight: 700;
      padding-left: 0.25rem;
    }

    font-size: var(--font-heading-3);
    font-weight: 700;
    padding: 1rem;

    @include background-img(1.5rem, calc(100% - 1rem) center);
    background-image: url(/assets/ic/chevron-down-bk.svg);

    @include media-breakpoint-up('md') {
      padding: 1rem 1.5rem;
    }

    &.active {
      background-image: url(/assets/ic/chevron-up-bk.svg);
      border-bottom: 1px solid var(--Border-DividerLight);

      @include media-breakpoint-up('lg') {
        border-bottom: 1px solid var(--Border-DividerMiddle);
      }
    }

    .checkbox-group {
      padding-top: 0;
      padding-bottom: 0;

      .checkmark {
        top: 0.1rem;
      }
    }
  }

  // 개별 제품
  .store-group-list {
    padding-left: 1rem;
    padding-right: 1rem;

    &.show {
      display: block;

      @include media-breakpoint-up('lg') {
        display: grid;
      }
    }

    display: none;

    @include media-breakpoint-up('md') {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    // 제품
    .store-item {
      display: flex;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      border-bottom: 1px solid var(--Border-DividerLight);

      > * + * {
        margin-left: 0.5rem;
      }

      .checkbox-group {
        display: block;
        padding: 0 0 0 1.5rem;
      }

      .product-item {
        flex: 1;

        .thumbnail {
          @include size(5rem);
        }

        .info-wrap {
          margin-top: 0;

          .title {
            margin-top: 0;
          }

          .stepper {
            margin-top: 0.75rem;

            @include media-breakpoint-up('lg') {
              margin-top: 0;
            }
          }

          @include media-breakpoint-up('lg') {
            display: flex;

            .desktop-flex-col {
              flex: 1;
              padding-right: 2.5rem;
            }
          }
        }

        .desktop-col {
          display: none;
          padding: 0;
          @include size(11.25rem, auto);
          text-align: center;

          + .desktop-col {
            @include size(7rem, auto);
          }

          .label {
            font-size: var(--font-body-2);
            margin-bottom: 0.5rem;
          }

          .pricing {
            font-size: var(--font-heading-3);
          }

          .info {
            color: var(--Font-Light);
            font-size: var(--font-body-3);
          }

          @include media-breakpoint-up('lg') {
            display: block;
          }
        }
      }

      .btn-delete {
        display: block;

        @include size(1.5rem);

        > img {
          @include size(1.5rem);
        }
      }
    }
  }

  // 제조사별 금액
  .pricing-group-list {
    @include size(100%, auto);
    padding: 1.5rem 1rem;

    @include media-breakpoint-up('md') {
      padding: 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      grid-template-columns: 1fr auto;

      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    display: none;

    &.show {
      display: block;

      @include media-breakpoint-up('lg') {
        display: grid;
      }
    }

    .pricing-item {
      @include size(100%, auto);
      display: block;

      @include media-breakpoint-up('lg') {
        display: grid;
        grid-template-columns: repeat(3, auto);
        width: fit-content;
      }

      + .pricing-item {
        margin-top: 1rem;

        @include media-breakpoint-up('lg') {
          margin-top: 0;
        }
      }

      @include media-breakpoint-up('lg') {
        .discount {
          order: 3;
        }
      }

      .pricing-col {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include media-breakpoint-up('lg') {
          width: fit-content;
          grid-template-columns: repeat(2, auto);
          align-self: center;
        }

        + .pricing-col {
          margin-top: 0.5rem;

          @include media-breakpoint-up('lg') {
            margin-top: 0;
          }
        }

        &.btn-payment {
          grid-template-columns: 1fr;
          margin-top: 1rem;

          @include media-breakpoint-up('lg') {
            margin-top: 0;
            margin-left: 2rem;
          }
        }

        .label {
          font-size: var(--font-body-2);
          color: var(--Font-Middle);
          line-height: 1.5rem;
        }

        .price {
          text-align: right;
          font-weight: 700;

          .unit {
            font-size: var(--font-body-2);
            font-weight: 400;
          }

          @include media-breakpoint-up('lg') {
            text-align: left;

            margin-left: 0.5rem;
          }
        }

        .info {
          font-size: var(--font-body-3);
          color: var(--Font-Light);

          @include media-breakpoint-up('lg') {
            display: none;
          }
        }

        .cal {
          display: none;

          @include media-breakpoint-up('lg') {
            display: inline-block;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
          }
        }
      }

      &.total-price {
        padding-top: 1rem;
        border-top: 1px solid var(--Border-DividerLight);

        .label {
          font-size: var(--font-body-1);
          color: var(--Font-Heavy);
          font-weight: 700;
          line-height: 2rem;

          @include media-breakpoint-up('lg') {
            line-height: 2.3rem;
          }
        }

        .price {
          text-align: right;
          font-size: var(--font-heading-2);

          .unit {
            font-size: var(--font-heading-3);
            font-weight: 400;
          }

          @include media-breakpoint-up('lg') {
            font-size: var(--font-heading-1);
            text-align: left;
            margin-left: 1rem;
          }
        }

        @include media-breakpoint-up('lg') {
          padding-top: 0;
          border-top: none;

          // grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

// 유의사항
.cart-notice {
  width: 100%;
  padding: 1.5rem 1rem 2.5rem 1rem;

  border-top: 0.5rem solid var(--gray-50);
  border-bottom: 0.5rem solid var(--gray-50);

  font-size: var(--font-body-2);
  color: var(--Font-Light);

  @include media-breakpoint-up('md') {
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  }

  @include media-breakpoint-up('lg') {
    display: flex;

    padding: 2.5rem 1.5rem;
    margin: 2.5rem 1.5rem;

    border-top: 1px solid var(--Border-DividerLight);
    border-bottom: 1px solid var(--Border-DividerLight);
  }

  @include media-breakpoint-up('xlg') {
    margin-left: auto;
    margin-right: auto;
  }

  &.payment-done {
    border-bottom: none;

    @include media-breakpoint-up('lg') {
      border-top: 1px solid var(--Border-DividerLight);
      border-bottom: 1px solid var(--Border-DividerLight);
    }
  }

  .title {
    @include background-img(1.125rem, 0 1px);
    background-image: url(/assets/ic/info-gr.svg);
    padding-left: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .list-group {
    list-style-type: disc;
    padding-left: 1.25rem;

    @include media-breakpoint-up('lg') {
      flex: 1;
      margin-left: 1.5rem;
    }

    li {
      + * {
        margin-top: 0.5rem;
      }
    }
  }

  .link {
    text-decoration: underline;
  }
}

.cart-banner-wrap {
  @include size(100%, auto);
  padding-left: 0;
  padding-right: 0;

  border-bottom: 0.5rem solid var(--gray-50);

  @include media-breakpoint-up('lg') {
    border-radius: var(--radius-product);
    overflow: hidden;
    border-bottom: none;

    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up('xlg') {
    padding-left: 0;
    padding-right: 0;
  }

  .banner {
    display: block;
    @include size(100%, auto);
    border-radius: var(--radius-product);
  }
}

// 장바구니 페이지 네비게이션 스타일
.mobile-bottom-nav {
  &.cart-nav {
    border-top: 1px solid var(--Border-DividerMiddle);

    @include media-breakpoint-up('lg') {
      display: none;
    }

    .handle {
      @include size(100%, 1rem);
      @include background-img(2.5rem 0.25rem, center center);
      background-image: url(/assets/ic/bottom-sheets-handle.svg);
    }

    .total-price {
      border-top: none;
      padding-top: 0;
      height: 1.75rem;
    }
  }
}

.desktop-cart-bottom-nav {
  display: none;
  position: fixed;
  bottom: 0;
  @include size(100%, var(--lg-BottomCartNavigaion));
  max-width: unset;
  padding: 0;

  background-color: var(--Theme-Teal-50);
  z-index: var(--z-index-header);

  border-top: 1px solid var(--Border-DividerMiddle);

  @include media-breakpoint-up('lg') {
    display: block;
  }

  .contents-wrap {
    display: flex;
    @include size(100%);
    padding: 1rem 1.5rem;

    @include media-breakpoint-up('xlg') {
      max-width: 1128px;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem 0rem;
    }

    .col {
      &.calc {
        flex: 1;

        line-height: 3.5rem;

        > * + * {
          margin-left: 0.5rem;
        }

        .cal {
          margin-right: 0.5rem;
        }

        .price {
          font-weight: 700;
          font-size: var(--font-heading-2);
        }
      }

      &.total {
        .label {
          font-weight: 700;
          font-size: var(--font-heading-2);
          margin-right: 1.5rem;
          line-height: 56px;
        }

        .btn {
          margin-top: -5px;
        }
      }
    }
  }
}
