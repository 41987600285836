// 스토어 제품 스타일

// Global Unit
.discount-percent {
  color: var(--Font-ProductRatio); //TODO: 밸류값 확인필요
  font-weight: 700;
}

.pricing {
  font-weight: 700;

  .unit {
    font-weight: 400;
  }
}

.prev-price {
  font-weight: 700;
  color: var(--gray-400);
  text-decoration: line-through;

  .unit {
    font-weight: 400;
  }
}

.badge-group {
  font-size: 0;

  > * {
    + * {
      margin-left: 0.25rem;
    }
  }
}

// Product Item Default
.product-item {
  &.horizontal {
    display: flex;

    .title {
      margin-bottom: 0;

      + .badge-group {
        margin-top: 0.5rem;
      }

      + .option {
        margin-top: 0.25rem;
      }
    }

    .thumbnail {
      @include size(6.5rem);
      margin-right: 1rem;
      margin-bottom: 0;
    }

    .info-wrap {
      flex: 1;

      &.center {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .pricing-wrap {
      margin-top: 0.5rem;

      > * {
        display: inline;

        + * {
          margin-left: 0.5rem;
        }
      }
    }
  }

  &.sm {
    .pricing {
      font-size: 14px;
      line-height: 160%; /* 1.4rem */
      letter-spacing: -0.00438rem;

      @include media-breakpoint-up('xlg') {
        font-size: var(--font-heading-4);
        line-height: 1.5rem; /* 150% */
        letter-spacing: -0.005rem;
      }

      .unit {
        font-size: 12px;

        @include media-breakpoint-up('xlg') {
          font-size: var(--font-body-1);
        }
      }
    }

    .title {
      font-size: var(--font-body-3);
      line-height: 1rem; /* 133.333% */
      letter-spacing: -0.00375rem;

      @include media-breakpoint-up('xlg') {
        font-size: var(--font-body-2);
        line-height: 1.25rem; /* 142.857% */
        letter-spacing: -0.00438rem;
      }
    }
  }

  .thumbnail {
    position: relative;
    @include size(100%);
    margin-bottom: 0.75rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.04); //TODO: 컬러 확인 필요
    aspect-ratio: 1;
    overflow: hidden;

    img {
      border-radius: 0.7rem;
      @include size(100%);
      object-fit: cover;
    }

    .thumbnail-badge {
      position: absolute;
      @include size(auto, 1.5rem);
      top: 0;
      left: 0;
      border-radius: 0;

      &.best {
        padding: 0 0.75rem;
        background-color: var(--Static-White);
        font-size: var(--font-body-2);
        font-weight: 700;
        border-radius: 0 0 var(--radius-s) 0;
      }
    }

    .thumbnail-dim {
      position: absolute;
      @include size(100%);
      top: 0;
      left: 0;
      background-color: var(--dim);

      @include background-img(4rem, center center);
      background-image: url(/assets/ic/badge-soldout.svg);
    }
  }

  .pricing {
    width: 100%;
    font-size: var(--font-heading-4);

    @include media-breakpoint-up('xlg') {
      font-size: var(--font-heading-3);
    }

    .discount-percent {
      margin-right: 0.25rem;
    }

    .unit {
      font-size: var(--font-body-2);

      @include media-breakpoint-up('xlg') {
        font-size: var(--font-body-1);
      }
    }
  }

  .title {
    width: 100%;
    margin-top: 0.25rem;
    margin-bottom: var(--space-xxs);
    white-space: normal;

    font-size: var(--font-body-2);
    line-height: 1.25rem; /* 142.857% */

    @include media-breakpoint-up('lg') {
      font-size: var(--font-body-1);
      line-height: 1.5rem; /* 150% */
    }
  }

  .option {
    font-size: var(--font-body-2);
    color: var(--Font-Light);
  }

  .amount {
    height: 1rem;
    line-height: 1rem;
    font-size: var(--font-body-2);
    color: var(--Font-Light);

    padding-left: 0.5rem;
    margin-left: 0.5rem;

    margin-top: 4px;

    border-left: 1px solid var(--Border-DividerLight);
  }

  .payment-price-amount-col {
    display: flex;
    margin-top: 0.5rem;

    .pricing {
      font-size: var(--font-body-1);
      width: auto;
    }
  }

  .store {
    font-size: var(--font-body-3);
    color: var(--Font-Middle);
  }
}

// Product List
.product-list {
  display: block;

  // 공통 제품 리스트 하위 속성
  &::after {
    clear: both;
    display: block;
    content: '';
  }

  > * {
    margin-right: 1rem;
    margin-bottom: 2.5rem;

    @include media-breakpoint-up('xlg') {
      margin-right: 1.5rem;
    }
  }

  // 제품 리스트 세로형
  &.vertical {
    display: flex;
    flex-wrap: wrap;

    // 기본 2열
    > * {
      flex: 0 0 calc(50% - 0.5rem); /* 아이템의 너비를 반으로 나눔 */

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      // md에서 3열
      @include media-breakpoint-up('md') {
        flex: 0 0 calc(33.333% - calc(2rem / 3));

        &:nth-of-type(2n) {
          margin-right: 1rem;
        }

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }

      // lg에서 4열
      @include media-breakpoint-up('lg') {
        flex: 0 0 calc(25% - calc(3rem / 4));

        &:nth-of-type(3n) {
          margin-right: 1rem;
        }

        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }

      // xlg에서 5열
      @include media-breakpoint-up('xlg') {
        flex: 0 0 calc(20% - calc(6rem / 5)); // 1.5rem * 4

        &:nth-of-type(2n) {
          margin-right: 1.5rem;
        }

        &:nth-of-type(3n) {
          margin-right: 1.5rem;
        }

        &:nth-of-type(4n) {
          margin-right: 1.5rem;
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }
      }
    }
  }

  // 제품 리스트 가로 스크롤형
  &.horizontal {
    display: flex;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
    //white-space: nowrap; FIXME: 마이페이지 확인용
    font-size: 0;

    &::-webkit-scrollbar {
      width: 0px;
      display: none;
      -ms-overflow-style: none; // IE and Edge
      scrollbar-width: none; // Firefox
    }

    // 기본 2.5열
    > * {
      //display: inline-block; FIXME: 마이페이지 확인용
      flex: 0 0 auto;
      width: calc((100% - 2rem) / 2.5); // 16px * 2
      vertical-align: top;

      &:last-child {
        margin-right: 0;
      }

      // lg에서 4열
      @include media-breakpoint-up('lg') {
        width: calc((100% - calc(1rem * 3)) / 4); // 16px * 3
      }

      // xlg에서 6열
      @include media-breakpoint-up('xlg') {
        width: calc((100% - calc(1.5rem * 5)) / 6); // 24px * 5
      }
    }
  }

  // 홈에서 사용되는 제품리스트 가로 스크롤형
  &.lg-5 {
    // 기본 2.5열
    > * {
      // xlg에서 5열
      @include media-breakpoint-up('xlg') {
        width: calc((100% - calc(1.5rem * 4)) / 5); // 24px * 4
      }
    }
  }

  // 제품 리스트 혼합형
  &.mixed {
    // 기본 3열 2행 플롯형
    display: flex;
    flex-wrap: wrap;

    // lg부터 가로형
    @include media-breakpoint-up('lg') {
      //display: block; FIXME: 마이페이지 확인용
      flex-wrap: unset;

      position: relative;
      overflow: hidden;
      overflow-x: scroll;
      //white-space: nowrap; FIXME: 마이페이지 확인용
      font-size: 0;

      &::-webkit-scrollbar {
        width: 0px;
        display: none;
        -ms-overflow-style: none; // IE and Edge
        scrollbar-width: none; // Firefox
      }
    }

    .title {
      @include multi-truncate(2);
    }

    // 기본 3열
    > * {
      flex: 0 0 calc(33.333% - calc(2rem / 3));

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      // lg에서 4열 가로정렬
      @include media-breakpoint-up('lg') {
        float: unset;
        //display: inline-block; FIXME: 마이페이지 확인용
        flex: 0 0 auto;
        margin-right: 1.5rem;

        width: calc((100% - calc(1.5rem * 3)) / 4); // 24px * 3

        &:nth-of-type(3n) {
          margin-right: 1.5rem;
        }
      }

      // lg에서 6열
      @include media-breakpoint-up('xlg') {
        width: calc((100% - calc(1.5rem * 5)) / 6); // 24px * 5

        &:nth-of-type(3n) {
          margin-right: 1.5rem;
        }

        &:nth-of-type(6n) {
          margin-right: 1.5rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

// 제품 리스트 가로 스크롤형 desktop 버튼
.product-list-wrap {
  position: relative;

  .btn-rounded {
    display: none;

    position: absolute;
    top: 5.2rem;
    left: -1.25rem;
    z-index: var(--z-index-arrow);

    &.right {
      left: unset;
      right: -1.25rem;
    }

    @include media-breakpoint-up('lg') {
      display: block;
    }
  }
}

// 제품 리스트 필터
.product-list-filter {
  display: flex;
  @include size(100%, auto);

  @include media-breakpoint-up('xlg') {
    padding: 1rem 0;
    border-bottom: 1px solid var(--Border-DividerHeavy);
    margin-bottom: 1.5rem;
  }

  &.filter-sm {
    @include media-breakpoint-up('lg') {
      border-bottom: 0;
      margin-bottom: 0;

      .result-value {
        font-size: var(--font-heading-3);
      }
    }
  }

  .result-value {
    flex: 1;
    @include size(100%);
    padding: 0.75rem 0;
    font-weight: 700;
    letter-spacing: -0.005rem;

    @include media-breakpoint-up('xlg') {
      padding: 0.37rem 0;
      font-size: var(--font-heading-2);
      line-height: 1.75rem; /* 140% */
      letter-spacing: -0.0125rem;
    }

    .img-reviewPoint {
      margin-left: 1rem;
      margin-top: -0.125rem;
    }
  }

  .btn-sorting {
    @include size(auto, 2.5rem);
    padding: 0 0.5rem;
    margin-top: 0.25rem;

    font-size: var(--font-body-2);
    color: var(--Font-Light);

    &.active {
      padding: 0 0.5rem 0 calc(0.5rem + 1.5rem + 0.25rem);
      @include background-img(1.5rem, 0.5rem center);
      background-image: url(/assets/ic/sorting.svg);
      color: var(--Font-Heavy);
    }
  }

  .mobile-list-sorting {
    display: block;

    @include media-breakpoint-up('lg') {
      padding: 2.5rem 0 1rem 0;
      display: none;
    }
  }

  .desktop-list-sorting {
    display: none;

    @include media-breakpoint-up('lg') {
      display: block;
    }

    > * {
      display: inline-block;
    }

    .btn-sorting {
      margin-top: 0;

      &.active {
        background-image: url(/assets/ic/checked.svg);
      }
    }
  }

  .date {
    font-size: var(--font-body-2);
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 2rem;
  }
}

// 제품 리스트 헤더
.product-list-header {
  position: relative;
  padding: 2.5rem 0 1rem 0;
  font-size: var(--font-heading-3);
  font-weight: 700;

  span {
    color: var(--Theme-Primary);
  }

  @include media-breakpoint-up('xlg') {
    font-size: var(--font-heading-2);
    padding: 2.5rem 0 1rem 0;
    margin-bottom: 1rem;
  }

  .btn-delete-all {
    position: absolute;
    bottom: 1.25rem;
    right: 0;
    display: block;
    color: var(--Font-Light, #7a7a85);
    font-size: var(--font-body-3);
    font-weight: 400;
  }
}

// Pagenation
.pagenation-group {
  padding: 1.5rem 0 2.5rem 0;

  .pagenation {
    display: none;
    @include size(auto);

    text-align: center;

    @include media-breakpoint-up('lg') {
      display: block;
    }

    > * {
      display: inline-block;
    }

    .page {
      padding: 0.75rem;
      color: var(--Font-Heavy);
      font-weight: 400;

      &.active {
        font-weight: 700;
      }
    }

    .btn-rounded {
      margin-right: 1.5rem;

      &.right {
        margin-left: 1.5rem;
        margin-right: 0;
      }
    }
  }

  .pagenation-mobile {
    @include media-breakpoint-up('lg') {
      display: none;
    }
  }
}

.pagenation-mixed {
  padding: 1.5rem 0 2.5rem 0;
  text-align: center;

  @include media-breakpoint-up('lg') {
    padding: 0;
  }

  > * {
    display: inline-block;
    //background-color: aqua;

    .btn-rounded {
      display: inline-block;
      position: relative;
      top: unset;
      left: unset;

      &.right {
        right: unset;
      }

      @include media-breakpoint-up('lg') {
        position: absolute;

        top: 3.9rem;
        left: -1.25rem;

        &.right {
          left: unset;
          right: -1.25rem;
        }
      }
    }
  }

  .page {
    @include size(auto);

    color: var(--Font-Light);
    font-weight: 400;
    padding: 0.5rem 1.25rem 0 1.25rem;

    @include media-breakpoint-up('lg') {
      display: none;
    }

    .active {
      font-weight: 700;
      color: var(--Font-Heavy);
    }
  }
}
