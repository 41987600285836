// Global Grid

.global-container {
  @include size(100%, auto);
  padding-top: var(--navbar-Mobile);

  @include media-breakpoint-up('lg') {
    padding-top: var(--navbar-Desktop);
  }

  &.with-navbar-expand {
    padding-top: var(--navbar-MobileExpand);

    @include media-breakpoint-up('lg') {
      padding-top: var(--navbar-Desktop);
    }
  }

  &.with-bottom-nav {
    padding-bottom: var(--sm-BottomNavigaion);
  }

  // 폐쇄몰 네비게이션 보완
  &.closure {
    @include media-breakpoint-up('lg') {
      padding-top: var(--navbar-DesktopClosure);
    }
  }

  // global 컨테이너 안에 컨텐츠를 감싸는 블록이 있어야 합니다.

  > * {
    padding-left: 1rem; // 16px gutter
    padding-right: 1rem; // 16px gutter

    @include media-breakpoint-up('md') {
      padding-left: 1.5rem; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    @include media-breakpoint-up('lg') {
      padding-left: 1.5rem; // 24px gutter
      padding-right: 1.5rem; // 24px gutter
    }

    @include media-breakpoint-up('xlg') {
      padding-left: 0;
      padding-right: 0;

      max-width: 1128px; // flexible + 36px gutter
      margin-left: auto;
      margin-right: auto;
    }

    &.mobile-mg-r-0 {
      padding-right: 0;

      @include media-breakpoint-up('lg') {
        padding: 0 1.5rem; // 24px gutter
      }

      @include media-breakpoint-up('xlg') {
        padding: 0; // 24px gutter
      }
    }

    &.mobile-mg-0 {
      padding-right: 0;
      padding-left: 0;

      @include media-breakpoint-up('lg') {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }

      @include media-breakpoint-up('xlg') {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

// 에러, 비밀번호 재설정 등 사용되는 컨테이너
.blank-container {
  padding-top: var(--navbar-Blank);
}

// 마이 페이지
.my-container {
  display: block;
  width: 100%;
  min-height: 80vh;
  padding-top: var(--navbar-Mobile);

  @include media-breakpoint-up('lg') {
    display: grid;
    grid-template-columns: 15rem 1fr;
    padding-top: var(--navbar-Desktop);
  }

  @include media-breakpoint-up('xlg') {
    max-width: 1128px; // flexible + 36px gutter
    margin-left: auto;
    margin-right: auto;
  }

  &.closure {
    @include media-breakpoint-up('lg') {
      padding-top: var(--navbar-DesktopClosure);
    }
  }

  .mobile-mg-r-0 {
    padding-right: 0;

    @include media-breakpoint-up('lg') {
      padding-right: 1.5rem;
    }

    @include media-breakpoint-up('xlg') {
      padding-right: 0;
    }
  }
}

.mobile-divider {
  @include size(100%, 8px);
  background-color: var(--gray-50);
  border-top: 1px solid var(--gray-100);

  @include media-breakpoint-up('lg') {
    display: none;
  }
}

// 데스크탑 전용 점보트론
.desktop-jumbotron {
  display: none;
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include media-breakpoint-up('lg') {
    display: block;
  }

  > img {
    @include size(2rem);
    margin-top: -0.6rem;
  }

  .text {
    display: inline-block;
    font-size: var(--font-heading-1);
    font-weight: 700;

    margin-left: 0.5rem;
  }

  .sub-text {
    display: block;
    color: var(--Font-Light, #7a7a85);
    font-size: var(--font-body-2);
    margin-top: 1rem;
  }

  &.section {
    text-align: left;
    padding-bottom: 0;

    .section-title {
      margin-bottom: 0;
    }
  }

  &.desktop-divider {
    @include media-breakpoint-up('lg') {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid var(--Border-DividerLight);
    }
  }

  + .jumbotron {
    @include media-breakpoint-up('lg') {
      padding-top: 0;
      margin-top: -1rem;
    }
  }
}

// 모바일-데스크탑 공통 점보트론
.jumbotron {
  text-align: center;
  padding-top: 1.5rem;

  @include media-breakpoint-up('lg') {
    padding-top: 2.5rem;
  }

  .title {
    display: block;
    font-size: var(--font-heading-1);
    font-weight: 700;

    + .sub-text {
      margin-top: 1rem;
    }
  }

  .sub-text {
    display: block;
    color: var(--Font-Light, #7a7a85);
    font-size: var(--font-body-2);
  }

  // 로그인 결과 페이지에서 쓰이는 스타일
  &.mobile-align-left {
    text-align: left;

    @include media-breakpoint-up('lg') {
      text-align: center;
    }

    .title {
      font-size: var(--font-heading-2);

      span {
        display: block;

        @include media-breakpoint-up('lg') {
          display: inline-block;

          + span {
            margin-left: 0.5rem;
          }
        }
      }

      @include media-breakpoint-up('lg') {
        font-size: var(--font-heading-1);
      }
    }
  }

  &.divider {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--Border-DividerLight);

    @include media-breakpoint-up('lg') {
      padding-bottom: 2.5rem;
    }
  }

  // 비밀번호 재설정에서 쓰이는 가운데 정렬 divider
  &.divider-centered {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;

    padding-bottom: 1.5rem;

    @include media-breakpoint-up('md') {
      width: calc(100% - 3rem);
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      margin-left: auto;
      margin-right: auto;
      max-width: 30rem;
      
      padding-bottom: 2.5rem;
    }

    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--Border-DividerLight);
  }
}

// 에러 환경에서 나오는 영역
.error-col {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @include size(fit-content);

  text-align: center;

  .img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include size(5.75rem, 5rem);
    margin-bottom: 2rem;
  }

  .title {
    font-size: var(--font-heading-1);
    color: var(--Font-Heavy);
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .sub-title {
    font-size: var(--font-body-2);
    color: var(--Font-Light);
  }

  .btn {
    margin-top: 2rem;
  }
}

// black 페이지에서 나오는 영역
.blank-copyright {
  text-align: center;
  font-size: var(--font-body-3);
  color: var(--Font-Middle);
}
